export const cirtnes_veidsValues = {
    100000000: "Kailcirte",
    100000001: "Izlases cirte",
    100000002: "Kopšanas cirte",
    100000003: "Sanitārā cirte",
    100000004: "Apauguma novākšana",
    100000005: "Neveikt darbību",
};

export const zagesanas_statussValues = [{
    statuss: 100000000,
    label: "Piešķirts",
}, {
    statuss: 100000001,
    label: "Uzsākts",
}, {
    statuss: 100000003,
    label: "Pārtraukts",
}, {
    statuss: 100000002,
    label: "Pabeigts",
}];

export const pievesanas_statussValues = [{
    statuss: 100000000,
    label: "Piešķirts",
}, {
    statuss: 100000001,
    label: "Uzsākts",
}, {
    statuss: 100000003,
    label: "Pārtraukts",
}, {
    statuss: 100000002,
    label: "Pabeigts",
}];

export const status_colorsValues = {
    100000000: "#f8cbad",
    100000001: "#fff2cc",
    100000003: "#f8cbad",
    100000002: "#c6e0b4",
};
