import * as React from "react";

interface SwitchSelectProps {
    onChange?(value): void;
    selected?: boolean | "";
    blankOption?: boolean;
}

export class SwitchSelect extends React.Component<SwitchSelectProps, {}> {
    render() {
        return (
            <select onChange={this.props.onChange} style={{ width: "100%" }}>
                {this.props.blankOption && <option value="" { ...this.props.selected === "" ? { selected: true } : {}}></option>}
                <option value={true as any} { ...this.props.selected === true ? { selected: true } : {}}>Jā</option>
                <option value={false as any} { ...this.props.selected === false ? { selected: true } : {}}>Nē</option>
            </select>
        );
    }
}
