import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";

import { NavLink, Redirect, withRouter } from "react-router-dom";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";

import { HeaderMenu } from "../../components/HeaderMenu";
import { ImageUploader } from "../../components/ImageUploader";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { SelectField } from "../../components/SelectD";
import { AVIABLITY_VALUES, BOOLEAN_VALUES, BOOLEAN_VALUES_CERTAMIE, CELA_ZIME_VALUES, CONDTION_VALUES } from "../../constants/constants";
import { IMAGE_TYPES } from "../../utils/constants";
import { Helpers } from "../../utils/helpers";
import { PropertyDevelopmentMin } from "../Properties/types/property";

interface PropertyDevelopmentState {
    redirect: boolean;
    loaded: boolean;
    data: PropertyDevelopmentMin;
    savingData: boolean;
}

interface PropertyDevelopmentProps extends LayoutProps {
    match: any;
    history: any[];
    form: any;
}

class PropertyDevelopmentMain extends React.Component<PropertyDevelopmentProps, PropertyDevelopmentState> {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loaded: false,
            data: {} as any,
            savingData: false,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/development`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                data: response.data.data || null,
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        })

    saveChanges = () => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                Helpers.showError("Neizdevās saglabāt datus");
            }

            this.setState({ savingData: true }, () => {
                axios.put(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/development`, {
                    ...values,
                }, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                .then(response => {
                    if (response.data.success) {
                        this.setState({ savingData: false, redirect: true });
                    } else {
                        this.setState({ savingData: false });
                        Helpers.showError("Neizdevās saglabāt datus");
                    }
                })
                .catch(_error => {
                    this.setState({ savingData: false });
                    Helpers.showError("Neizdevās saglabāt datus");
                });
            });
        });
    }

    onValueChange = (field, value) => {
        const tempData = { ...this.state.data };
        tempData[field] = value;

        this.setState({ data: tempData });
    }

    redirectStackingPage = () => {
        this.props.history.push(`/ipasums/${this.props.match.params.propertyId}/atlikums_krautuve`);
    }

    // tslint:disable-next-line:cyclomatic-complexity
    render() {
        const propertyId = this.props.match.params.propertyId;
        const data = this.state.data;
        const { getFieldProps } = this.props.form;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}`}/>;
        }

        const loadedPageContent =
            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
                <Form>
                    <Grid>
                        <Header size="small" className="no--padding color--grey">Izstrādes kvalitātes kontrole</Header>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Zāģēšanas robežas pārkāptas?</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("robezas", {
                                        initialValue: data.robezas || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>
                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Pieļaujamais šķērslaukums?</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("skerslaukums", {
                                        initialValue: data.skerslaukums || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>
                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Iezīmēti cērtamie koki?</label>
                                <SelectField
                                    options={BOOLEAN_VALUES_CERTAMIE}
                                    {...getFieldProps("certamie_koki", {
                                        initialValue: data.certamie_koki || BOOLEAN_VALUES_CERTAMIE[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>
                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Tiek ievērota darba drošība?</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("drosiba", {
                                        initialValue: data.drosiba || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Header size="small" className="no--padding color--grey">Sortimenta kvalitātes kontrole</Header>
                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Sortimenti pareizi?</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("sortimenti", {
                                        initialValue: data.sortimenti || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>
                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Cērtot veidojas brāķi?</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("brakis", {
                                        initialValue: data.brakis || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Header size="small" className="no--padding color--grey">Krautuvju kvalitātes kontrole</Header>
                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Krautuvē pareizi krautnējumi?</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("krautnejumi", {
                                        initialValue: data.krautnejumi || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>
                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Pievešanas ceļas stāvoklis</label>
                                <SelectField
                                    options={CONDTION_VALUES}
                                    {...getFieldProps("pievesana", {
                                        initialValue: data.pievesana || CONDTION_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>
                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Piebraukšanas ceļa stāvoklis</label>
                                <SelectField
                                    options={AVIABLITY_VALUES}
                                    {...getFieldProps("piebrauksana", {
                                        initialValue: data.piebrauksana || AVIABLITY_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>
                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Foto ar krautuvi</label>

                                <ImageUploader
                                    type={IMAGE_TYPES.KRAUTUVE}
                                    propertyId={this.props.match.params.propertyId}
                                    cadastreNumber={data.cadastre_number}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Foto ar cirsmu</label>

                                <ImageUploader
                                    type={IMAGE_TYPES.CIRSMA}
                                    propertyId={this.props.match.params.propertyId}
                                    cadastreNumber={data.cadastre_number}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Ceļa zīme</label>
                                <SelectField
                                    options={CELA_ZIME_VALUES}
                                    {...getFieldProps("cela_zime", {
                                        initialValue: data.cela_zime || CELA_ZIME_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                            <Divider />
                        </Responsive>

                        <Grid.Row>
                            <div className="buttons--row">
                                <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" } }}>
                                    <Button onClick={this.saveChanges} color="green" loading={this.state.savingData}>
                                        <Icon name="save"/>
                                        Saglabāt
                                    </Button>
                                </ResponsiveElement>
                            </div>
                        </Grid.Row>
                    </Grid>
                </Form>
            </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        const backButton = <NavLink to={`/ipasums/${propertyId}/ipasuma_apmeklejums`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Izstrāde
                                        <Header.Subheader>{data && data.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Izstrāde
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}/ipasuma_apmeklejums`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> {data && data.felling_area_name}
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}

export const PropertyDevelopment = createForm()(withRouter(PropertyDevelopmentMain as any));
