import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";

import { NavLink, Redirect } from "react-router-dom";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { SelectField } from "../../components/SelectD";
import { BOOLEAN_VALUES } from "../../constants/constants";
import { Helpers } from "../../utils/helpers";

interface WorkSafetyState {
    redirect: boolean;
    loaded: boolean;
    propertyData: any;
    safetyData: any;
    savingData: boolean;
}

interface WorkSafetyProps extends LayoutProps {
    match: any;
    form: any;
}

class WorkSafetyWrapped extends React.Component<WorkSafetyProps, WorkSafetyState> {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loaded: false,
            propertyData: {},
            safetyData: {},
            savingData: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () =>
        axios.all([
            this.fetchPropertyData(),
            this.fetchPropertyHabitats(),
        ]).then(axios.spread((property, habitats) => {
            if (property.data.success === false || habitats.data.success === false) {
                Helpers.showError("Neizdevās ielādēt īpašuma datus!");
            }

            this.setState({
                loaded: true,
                propertyData: property.data.data,
                safetyData: habitats.data.data,
            });
        }))

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })

    fetchPropertyHabitats = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/work_safety`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })

    onValueChange = (field, value) => {
        const tempData = { ...this.state.safetyData };

        if (value === "true" || value === "false") {
            value = value === "true";
        }

        tempData[field] = value;

        this.setState({ safetyData: tempData });
    }

    saveChanges = () => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                Helpers.showError("Neizdevās saglabāt datus");
            }

            this.setState({ savingData: true }, () => {
                axios.put(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/work_safety`, {
                    ...values,
                }, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                .then(response => {
                    if (response.data.success) {
                        this.setState({ savingData: false, redirect: true });
                    } else {
                        this.setState({ savingData: false });
                        Helpers.showError("Neizdevās saglabāt datus");
                    }
                })
                .catch(_error => {
                    this.setState({ savingData: false });
                    Helpers.showError("Neizdevās saglabāt datus");
                });
            });
        });
    }

    // tslint:disable-next-line:cyclomatic-complexity
    render() {
        const { getFieldProps, getFieldValue } = this.props.form;
        const propertyId = this.props.match.params.propertyId;
        const data = this.state.safetyData;
        const isFalse = (fieldname) => getFieldValue(fieldname) === BOOLEAN_VALUES[1].id;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}`}/>;
        }

        const loadedPageContent = <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
            <Form>
                <Grid>
                    {data.work_safety_completed && <Grid.Row>
                        <Message warning style={{ display: "block", width: "100%", textAlign: "center" }}>
                            <Message.Header>NODOTS!</Message.Header>
                        </Message>
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Mežizstrādātājs</label>
                            <input
                                {...getFieldProps("mezizstradatajs", {
                                    initialValue: data.mezizstradatajs || "",
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Piegādātajs</label>
                            <input
                                {...getFieldProps("piegadatajs", {
                                    initialValue: data.piegadatajs || "",
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Individuālie aizsardzības līdzekļi</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("aizsardzibas_lidzekli", {
                                    initialValue: data.aizsardzibas_lidzekli || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>

                    {isFalse("aizsardzibas_lidzekli") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Individuālie aizsardzības līdzekļi (Komentārs)</label>
                            <textarea
                                {...getFieldProps("aizsardzibas_lidzekli_komentars", {
                                    initialValue: data.aizsardzibas_lidzekli_komentars || "",
                                })}
                            />
                        </Form.Field>

                        <Divider />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Drošības zīmes, to pielietojums</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("drosibas_zimes", {
                                    initialValue: data.drosibas_zimes || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>

                    {isFalse("drosibas_zimes") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Drošības zīmes, to pielietojums (Komentārs)</label>
                            <textarea
                                {...getFieldProps("drosibas_zimes_komentars", {
                                    initialValue: data.drosibas_zimes_komentars || "",
                                })}
                            />
                        </Form.Field>

                        <Divider />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Mežsaimniecībā nodarbinātā/VID uzņēmuma reģ. Apliecības</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("nodarbinata", {
                                    initialValue: data.nodarbinata || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>

                    {isFalse("nodarbinata") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Mežsaimniecībā nodarbinātā/VID uzņēmuma reģ. Apliecības (Komentārs)</label>
                            <textarea
                                {...getFieldProps("nodarbinata_komentars", {
                                    initialValue: data.nodarbinata_komentars || "",
                                })}
                            />
                        </Form.Field>

                        <Divider />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Pirmās palīdzības komplekts</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("pirma_palidziba", {
                                    initialValue: data.pirma_palidziba || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>

                    {isFalse("pirma_palidziba") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Pirmās palīdzības komplekts (Komentārs)</label>
                            <textarea
                                {...getFieldProps("pirma_palidziba_komentars", {
                                    initialValue: data.pirma_palidziba_komentars || "",
                                })}
                            />
                        </Form.Field>

                        <Divider />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Vieta, kur nodarbinātajiem sasildīties, atpūsties, uzsildīt ēdienu</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("atputas_zona", {
                                    initialValue: data.atputas_zona || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    {isFalse("atputas_zona") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Vieta, kur nodarbinātajiem sasildīties, atpūsties, uzsildīt ēdienu (Komentārs)</label>
                            <textarea
                                {...getFieldProps("atputas_zona_komentars", {
                                    initialValue: data.atputas_zona_komentars || "",
                                })}
                            />
                        </Form.Field>

                        <Divider />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Pieejami sakaru līdzekļi</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("sakaru_lidzekli", {
                                    initialValue: data.sakaru_lidzekli || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    {isFalse("sakaru_lidzekli") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Pieejami sakaru līdzekļi (Komentārs)</label>
                            <textarea
                                {...getFieldProps("sakaru_lidzekli_komentars", {
                                    initialValue: data.sakaru_lidzekli_komentars || "",
                                })}
                            />
                        </Form.Field>

                        <Divider />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Atbilstoša tehnika, darba rīki, aprīkojums</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("atbilstosa_tehnika", {
                                    initialValue: data.atbilstosa_tehnika || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    {isFalse("atbilstosa_tehnika") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Atbilstoša tehnika, darba rīki, aprīkojums (Komentārs)</label>
                            <textarea
                                {...getFieldProps("atbilstosa_tehnika_komentars", {
                                    initialValue: data.atbilstosa_tehnika_komentars || "",
                                })}
                            />
                        </Form.Field>

                        <Divider />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Tehnika aprīkota ar ugunsdzēsības intventāru</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("ugunsdzesibas_inventars", {
                                    initialValue: data.ugunsdzesibas_inventars || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    {isFalse("ugunsdzesibas_inventars") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Tehnika aprīkota ar ugunsdzēsības intventāru (Komentārs)</label>
                            <textarea
                                {...getFieldProps("ugunsdzesibas_inventars_komentars", {
                                    initialValue: data.ugunsdzesibas_inventars_komentars || "",
                                })}
                            />
                        </Form.Field>

                        <Divider />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Krautuves atrašanās vieta atbilst noteikumiem</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("krautuve_atbilst_noteikumiem", {
                                    initialValue: data.krautuve_atbilst_noteikumiem || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    {isFalse("krautuve_atbilst_noteikumiem") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Krautuves atrašanās vieta atbilst noteikumiem (Komentārs)</label>
                            <textarea
                                {...getFieldProps("krautuve_atbilst_noteikumiem_komentars", {
                                    initialValue: data.krautuve_atbilst_noteikumiem_komentars || "",
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>}

                    <Divider />

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Kokmateriālu noveitojums krautuvē atbilst noteikumiem</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("kokmaterialu_novietojums", {
                                    initialValue: data.kokmaterialu_novietojums || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    {isFalse("kokmaterialu_novietojums") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Kokmateriālu noveitojums krautuvē atbilst noteikumiem (Komentārs)</label>
                            <textarea
                                {...getFieldProps("kokmaterialu_novietojums_komentars", {
                                    initialValue: data.kokmaterialu_novietojums_komentars || "",
                                })}
                            />
                        </Form.Field>

                        <Divider />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Tehnoloģiskā karte sagatavota korekti</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("tehnologiska_karte", {
                                    initialValue: data.tehnologiska_karte || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    {isFalse("tehnologiska_karte") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Tehnoloģiskā karte sagatavota korekti (Komentārs)</label>
                            <textarea
                                {...getFieldProps("tehnologiska_karte_komentars", {
                                    initialValue: data.tehnologiska_karte_komentars || "",
                                })}
                            />
                        </Form.Field>

                        <Divider />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Darbi tiek veikti atbilstoši tehnoloģiskajai kartei</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("abilstosi_teh_kartei", {
                                    initialValue: data.abilstosi_teh_kartei || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    {isFalse("abilstosi_teh_kartei") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Darbi tiek veikti atbilstoši tehnoloģiskajai kartei (Komentārs)</label>
                            <textarea
                                {...getFieldProps("abilstosi_teh_kartei_komentars", {
                                    initialValue: data.abilstosi_teh_kartei_komentars || "",
                                })}
                            />
                        </Form.Field>

                        <Divider />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Ievērotas noteiktās drošības joslas (zonas)</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("drosibas_joslas", {
                                    initialValue: data.drosibas_joslas || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    {isFalse("drosibas_joslas") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Ievērotas noteiktās drošības joslas (Komentārs)</label>
                            <textarea
                                {...getFieldProps("drosibas_joslas_komentars", {
                                    initialValue: data.drosibas_joslas_komentars || "",
                                })}
                            />
                        </Form.Field>

                        <Divider />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Ievēroti koku gāšanas noteikumi</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("koku_gasanas_noteikumi", {
                                    initialValue: data.koku_gasanas_noteikumi || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    {isFalse("koku_gasanas_noteikumi") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Ievēroti koku gāšanas noteikumi (Komentārs)</label>
                            <textarea
                                {...getFieldProps("koku_gasanas_noteikumi_komentars", {
                                    initialValue: data.koku_gasanas_noteikumi_komentars || "",
                                })}
                            />
                        </Form.Field>

                        <Divider />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Informācija par darba un atpūtas laika noteikumu neievērošanu</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("atputas_neieverosana", {
                                    initialValue: data.atputas_neieverosana || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    {isFalse("atputas_neieverosana") && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Informācija par darba un atpūtas laika noteikumu neievērošanu (Komentārs)</label>
                            <textarea
                                {...getFieldProps("atputas_neieverosana_komentars", {
                                    initialValue: data.atputas_neieverosana_komentars || "",
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Neatbilstību novēršanas līdz</label>
                            <input
                                {...getFieldProps("neatbilstibu_noversana", {
                                    initialValue: data.neatbilstibu_noversana || "",
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Atkārtota pārbaude nepieciešama?</label>
                            <SelectField
                                options={BOOLEAN_VALUES}
                                {...getFieldProps("atkartota_parbaude", {
                                    initialValue: data.atkartota_parbaude || BOOLEAN_VALUES[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>

                    <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                        <Divider />
                    </Responsive>

                    <Grid.Row >
                        <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" } }}>
                            <Button onClick={this.saveChanges} color="green" loading={this.state.savingData}>
                                <Icon name="save"/>
                                Saglabāt
                            </Button>
                        </ResponsiveElement>
                    </Grid.Row>
                </Grid >
            </Form>
        </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        const backButton = <NavLink to={`/ipasums/${propertyId}`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Biotopu veidlapa
                                        <Header.Subheader>{data && data.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Biotopu veidlapa
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> {data && data.felling_area_name}
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}

export const WorkSafety = createForm()(WorkSafetyWrapped as any);
