
export const SASKANOJUMI_OPTIONS = [{
    id: "",
    label: "",
}, {
    id: 111110000,
    label: "Viss saskaņots",
}, {
    id: 111110001,
    label: "Nepieciešams saskaņojums",
}];

export const DOKUMENTI_NOSUTITI = [{
    id: null,
    label: "",
}, {
    id: 111110000,
    label: "Jā",
}, {
    id: 111110001,
    label: "Nē",
}];

export const LIGUMA_PIELIKUMS = [{
    id: null,
    label: "",
}, {
    id: 100000000,
    label: "Nav līguma",
}, {
    id: 100000001,
    label: "Nosūtīts parakstīšanai",
}, {
    id: 100000002,
    label: "Saņemts parakstīts",
}];

export const ZAGESANA = [{
    id: null,
    label: "",
}, {
    id: 100000003,
    label: "Nav uzsākta",
}, {
    id: 100000000,
    label: "Uzsākta",
}, {
    id: 100000001,
    label: "Pārtraukta",
}, {
    id: 100000005,
    label: "Atsākta",
}, {
    id: 100000002,
    label: "Pabeigta",
}];

export const PIEVESANA = [{
    id: null,
    label: "",
}, {
    id: 100000003,
    label: "Nav uzsākta",
}, {
    id: 100000000,
    label: "Uzsākta",
}, {
    id: 100000001,
    label: "Pārtraukta",
}, {
    id: 100000005,
    label: "Atsākta",
}, {
    id: 100000002,
    label: "Pabeigta",
}];

export const SAIMNIEKAM_ATSTATS_MATERIALS = [{
    id: null,
    label: "",
}, {
    id: 100000000,
    label: "Ir",
}, {
    id: 100000003,
    label: "Vajag atstāt",
}, {
    id: 100000002,
    label: "Nevajag atstāt",
}];

export const KRAUTUVES_STATUSS = [{
    id: null,
    label: "",
}, {
    id: 100000002,
    label: "Krautuve nav novesta",
}, {
    id: 100000000,
    label: "Krautuve novesta!",
}, {
    id: 100000001,
    label: "Krautuve novesta!",
}, {
    id: 100000003,
    label: "Nav jākontrolē",
}];
