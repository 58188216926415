import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";
import { NavLink, Redirect, withRouter } from "react-router-dom";

import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Message from "semantic-ui-react/dist/commonjs/collections/Message/Message";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { SelectField } from "../../components/SelectD";
import { BOOLEAN_VALUES, SIMPLE_OPTIONS } from "../../constants/constants";
import { Helpers } from "../../utils/helpers";
import { PropertyBeforeDevelopment } from "../Properties/types/property";

interface BeforeDevelopmentState {
    redirect: boolean;
    loaded: boolean;
    data: PropertyBeforeDevelopment;
    savingData: boolean;
    completeSection: boolean;
}

interface BeforeDevelopmentProps extends LayoutProps {
    match: any;
    form: any;
}

const fieldLabel = [{
    fieldId: "saskan_ar_kaimin",
    label: "Saskaņošana ar kaimiņiem par pievešanu",
}, {
    fieldId: "saskan_kraut_izveidi",
    label: "Saskaņošana par krautuves izveidi",
}, {
    fieldId: "saskan_cela_izmant",
    label: "Saskaņošana par ceļa izmantošanu",
}, {
    fieldId: "cirsanas_apliecinajums",
    label: "Ciršanas apliecinājums",
}, {
    fieldId: "liguma_pielikums",
    label: "Līguma pielikums",
}, {
    fieldId: "nogabalu_plans",
    label: "Nogabalu plāns",
}, {
    fieldId: "sortimentu_lapa",
    label: "Sortimentu lapa",
}, {
    fieldId: "pieves_cels_daba",
    label: "Pievešanas ceļš iezīmēts dabā",
}, {
    fieldId: "kraut_vieta_iezim",
    label: "Krautuves vieta iezīmēta dabā",
}, {
    fieldId: "tehn_karte",
    label: "Tehnoloģiskā karte",
}];

const getFieldLabel = (id) => {
    const labelObject = fieldLabel.find(item => item.fieldId === id);
    return labelObject ? labelObject.label : id;
};

class BeforeDevelopmentMain extends React.Component<BeforeDevelopmentProps, BeforeDevelopmentState> {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loaded: false,
            data: {} as any,
            savingData: false,
            completeSection: false,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/before_development`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                data: response.data.data || null,
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        })

    completeChanges = () => {
        // Check if listed fields ar filled
        this.props.form.validateFields((error, values) => {
            if (error) {
                Helpers.showError("Neizdevās saglabāt datus");
            }

            // Get list with fields that contains "Nav" value
            const missingFields = Object.keys(values).reduce((list, key) => {
                if (values[key] === "Nav") {
                    list.push(key);
                }
                return list;
            }, [] as any[]);

            if (missingFields.length > 0) {
                const errorFields = <div>
                    <div>Lai sāktu darbus jums trūkst:</div>
                    <ul className="error--list">
                        {missingFields.map(item => (<li>{getFieldLabel(item)}</li>))}
                    </ul>
                </div>;

                Helpers.showError(errorFields, 6000);
                return;
            }

            this.processData(true);
        });
    }

    saveChanges = () => this.processData();

    processData = (completed = false) => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                Helpers.showError("Neizdevās saglabāt datus");
            }

            this.setState({ savingData: true }, () => {
                axios.put(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/before_development`, {
                    ...values,
                    ...(completed && { completed: true }),
                }, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                .then(response => {
                    if (response.data.success) {
                        this.setState({ savingData: false, redirect: true });
                    } else {
                        this.setState({ savingData: false });
                        Helpers.showError("Neizdevās saglabāt datus");
                    }
                })
                .catch(_error => {
                    this.setState({ savingData: false});
                    Helpers.showError("Neizdevās saglabāt datus");
                });
            });
        });
    }

    render() {
        const { getFieldProps } = this.props.form;
        const propertyId = this.props.match.params.propertyId;
        const data = this.state.data;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}`}/>;
        }

        const loadedPageContent =
            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
                <Form>
                    <Grid>
                        {this.state.data.before_develop_completed && <Grid.Row>
                            <Message warning style={{ display: "block", width: "100%", textAlign: "center" }}>
                                <Message.Header>DARBI UZSĀKTI!</Message.Header>
                            </Message>
                        </Grid.Row>}

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>{getFieldLabel("saskan_ar_kaimin")}</label>
                                <SelectField
                                    options={SIMPLE_OPTIONS}
                                    {...getFieldProps("saskan_ar_kaimin", {
                                        initialValue: data.saskan_ar_kaimin || SIMPLE_OPTIONS[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Saskaņošana par krautuves izveidi</label>
                                <SelectField
                                    options={SIMPLE_OPTIONS}
                                    {...getFieldProps("saskan_kraut_izveidi", {
                                        initialValue: data.saskan_kraut_izveidi || SIMPLE_OPTIONS[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Saskaņošana par ceļa izmantošanu</label>
                                <SelectField
                                    options={SIMPLE_OPTIONS}
                                    {...getFieldProps("saskan_cela_izmant", {
                                        initialValue: data.saskan_cela_izmant || SIMPLE_OPTIONS[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Ciršanas apliecinājums</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("cirsanas_apliecinajums", {
                                        initialValue: data.cirsanas_apliecinajums || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Līguma pielikums</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("liguma_pielikums", {
                                        initialValue: data.liguma_pielikums || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Nogabalu plāns</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("nogabalu_plans", {
                                        initialValue: data.nogabalu_plans || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Sortimentu lapa</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("sortimentu_lapa", {
                                        initialValue: data.sortimentu_lapa || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Skices</label>
                                <SelectField
                                    options={SIMPLE_OPTIONS}
                                    {...getFieldProps("skices", {
                                        initialValue: data.skices || SIMPLE_OPTIONS[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Pievešanas ceļš iezīmēts dabā</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("pieves_cels_daba", {
                                        initialValue: data.pieves_cels_daba || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Krautuves vieta iezīmēta dabā</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("kraut_vieta_iezim", {
                                        initialValue: data.kraut_vieta_iezim || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Tehnoloģiskā karte</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("tehn_karte", {
                                        initialValue: data.tehn_karte || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                            <Divider />
                        </Responsive>

                        {!this.state.data.before_develop_completed && <Grid.Row>
                            {!this.state.completeSection && <div className="buttons--row">
                                <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" }}}>
                                    <Button onClick={this.saveChanges} color="green" loading={this.state.savingData}>
                                        <Icon name="save"/>
                                        Saglabāt
                                    </Button>
                                </ResponsiveElement>
                            </div>}
                            {!this.state.savingData && <div className="buttons--row">
                                <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" }}}>
                                    <Button onClick={this.completeChanges} loading={this.state.completeSection}>
                                        <Icon name="share"/>
                                        Darbi sākti
                                    </Button>
                                </ResponsiveElement>
                            </div>}
                        </Grid.Row>}
                    </Grid>
                </Form>
            </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        const backButton = <NavLink to={`/ipasums/${this.props.match.params.propertyId}`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Pirms izstrādes
                                        <Header.Subheader>{data && data.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Pirms izstrādes
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> {data && data.felling_area_name}
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}

export const BeforeDevelopment = createForm()(withRouter(BeforeDevelopmentMain as any));
