import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { Button, Form, Loader, Modal, Table, TextArea } from "semantic-ui-react";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import { HeaderMenu } from "../../components/HeaderMenu";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { Helpers } from "../../utils/helpers";
import {
    cirtnes_veidsValues,
    pievesanas_statussValues,
    status_colorsValues,
    zagesanas_statussValues,
} from "./helpers/helpers";
import { JobTasksState } from "./type/jobTask.type";

interface DevelopmentStatusMainProps {
    match: any;
    form: any;
}

class JobTasksMain extends React.Component<DevelopmentStatusMainProps, JobTasksState> {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loaded: false,
            data: [],
            property: {} as any,
            savingData: false,
            completeSection: false,
            modalOpen: false,
            activeModalData: {
                item: null,
                type: null,
            },
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    changeStatus = (task, type: "zagesana" | "pievesana") => {
        const tempData = [...this.state.data];
        const taskIndex = tempData.findIndex(tempTask => tempTask.id === task.id);

        if (taskIndex > -1) {
            const taskObject = tempData[taskIndex];

            if (type === "zagesana") {
                const currentStateIndex = zagesanas_statussValues.findIndex(statuss => statuss.statuss === taskObject.zagesanas_statuss);
                const nextStatussIndex = currentStateIndex === zagesanas_statussValues.length - 1 ? 0 : currentStateIndex + 1;

                taskObject.zagesanas_statuss = zagesanas_statussValues[nextStatussIndex].statuss;
            }

            if (type === "pievesana") {
                const currentStateIndex = pievesanas_statussValues.findIndex(statuss => statuss.statuss === taskObject.pievesanas_statuss);
                const nextStatussIndex = currentStateIndex === pievesanas_statussValues.length - 1 ? 0 : currentStateIndex + 1;

                taskObject.pievesanas_statuss = pievesanas_statussValues[nextStatussIndex].statuss;
            }
        }

        this.setState({ data: tempData }, () => {
            this.updateRecord(task.id);
        });
    }

    updateRecord = (recordId: string) => {
        const propertyId = this.props.match.params.propertyId;
        const tempData = [...this.state.data];
        const task = tempData.find(tempTask => tempTask.id === recordId);

        if (!task) {
            Helpers.showError("Neizdevās saglabāt datus!");
            return;
        }

        axios.put(`${process.env.baseUrl}/api/properties/${propertyId}/development_status/darba_uzdevums`, {
            ...task,
        }, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .catch(_error => {
            Helpers.showError("Neizdevās saglabāt datus!");
        });
    }

    getStatusLabel = (value, type: "zagesana" | "pievesana") => {
        if (type === "zagesana") {
            const object = zagesanas_statussValues.find(status => status.statuss === value);
            return object ? object.label : null;
        }

        if (type === "pievesana") {
            const object = pievesanas_statussValues.find(status => status.statuss === value);
            return object ? object.label : null;
        }

        return null;
    }

    showCommentModal = (task, type) => {
        this.setState({
            activeModalData: {
                item: task,
                type,
            },
            modalOpen: true,
        });
    }

    fetchPropertyData = () => {
        const propertyId = this.props.match.params.propertyId;

        axios.get(`${process.env.baseUrl}/api/properties/${propertyId}/development_status/darba_uzdevums`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                data: response.data.data || [],
                property: response.data.property || {},
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        });
    }

    formatActionButtons = (task, type: "zagesana" | "pievesana") => {
        if (type === "zagesana") {
            return <div>
                <span
                    style={{ background: status_colorsValues[task.zagesanas_statuss] }}
                    className="statuss-button"
                    onClick={() => this.changeStatus(task, type)}
                >
                    {this.getStatusLabel(task.zagesanas_statuss, type)}
                </span>
                <span>
                    <Button
                        size="small"
                        basic
                        color="black"
                        onClick={() => this.showCommentModal(task, type)}
                    >
                        Komentārs
                    </Button>
                </span>
            </div>;
        }

        if (type === "pievesana") {
            return <div>
                <span
                    style={{ background: status_colorsValues[task.pievesanas_statuss] }}
                    className="statuss-button"
                    onClick={() => this.changeStatus(task, type)}
                >
                    {this.getStatusLabel(task.pievesanas_statuss, type)}
                </span>
                <span>
                    <Button
                        size="small"
                        basic
                        color="black"
                        onClick={() => this.showCommentModal(task, type)}
                    >
                        Komentārs
                    </Button>
                </span>
            </div>;
        }

        return null;
    }

    closeModal = () => this.setState({
        modalOpen: false,
        activeModalData: {
            item: null,
            type: null,
        },
    })

    modalComment = () => {
        if (this.state.activeModalData.type === "zagesana") {
            return this.state.activeModalData.item.komentars;
        }

        if (this.state.activeModalData.type === "pievesana") {
            return this.state.activeModalData.item.komentars_pievesana;
        }

        return "";
    }

    changeComment = (e) => {
        const tempData = [...this.state.data];
        const taskIndex = tempData.findIndex(tempTask => tempTask.id === this.state.activeModalData.item.id);

        if (taskIndex > -1) {
            const taskObject = tempData[taskIndex];

            if (this.state.activeModalData.type === "zagesana") {
                taskObject.komentars = e.target.value;
            }

            if (this.state.activeModalData.type === "pievesana") {
                taskObject.komentars_pievesana = e.target.value;
            }
        }

        this.setState({ data: tempData });
    }

    saveComment = () => {
        this.updateRecord(this.state.activeModalData.item.id);
        this.closeModal();
    }

    sortData = (data) =>
        data.sort((a, b) => {
            const aData = a.nogabals.split("kv");
            const aDataNogabals = aData[1].split("nog");
            const aKvartals = +aData[0];
            const aNogabals = +aDataNogabals[0].match(/\d+/)[0];

            const bData = b.nogabals.split("kv");
            const bDataNogabals = bData[1].split("nog");
            const bKvartals = +bData[0];
            const bNogabals = +bDataNogabals[0].match(/\d+/)[0];

            return aKvartals - bKvartals || aNogabals - bNogabals;
        })

    // tslint:disable-next-line:cyclomatic-complexity
    render() {
        const { property } = this.state;
        const propertyId = this.props.match.params.propertyId;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}/izstrades_statusi`}/>;
        }

        const loadedPageContent =
            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
                <Form style={{ background: "#fff", padding: "10px 15px" }}>
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Nogabals</Table.HeaderCell>
                                <Table.HeaderCell>Cirtes veids</Table.HeaderCell>
                                <Table.HeaderCell>Zāģēšana</Table.HeaderCell>
                                <Table.HeaderCell>Pievešana</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.sortData(this.state.data).map(task => (
                                <Table.Row>
                                    <Table.Cell>{task.nogabals}</Table.Cell>
                                    <Table.Cell>{cirtnes_veidsValues[task.cirtnes_veids]}</Table.Cell>
                                    <Table.Cell>{this.formatActionButtons(task, "zagesana")}</Table.Cell>
                                    <Table.Cell>{this.formatActionButtons(task, "pievesana")}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>

                    <Modal size="small" open={this.state.modalOpen} onClose={this.closeModal}>
                        <Modal.Header>Komentārs</Modal.Header>
                        <Modal.Content>
                            <p>
                                <TextArea
                                    placeholder={this.modalComment()}
                                    style={{ width: "100%", minHeight: 100 }}
                                    onChange={this.changeComment}
                                />
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                positive
                                icon="checkmark"
                                labelPosition="right"
                                content="Apstiprināt"
                                onClick={this.saveComment}
                            />
                        </Modal.Actions>
                    </Modal>
                </Form>
            </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        const backButton = <NavLink to={`/ipasums/${this.props.match.params.propertyId}/izstrades_statusi`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Darba uzdevumi
                                        <Header.Subheader>{property && property.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Darba uzdevumi
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}/izstrades_statusi`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> {property && property.felling_area_name}
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}

export const JobTasks = createForm()(withRouter(JobTasksMain as any));
