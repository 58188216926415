export const stackingTypes = [
    { title: "10 (PZBA) [vecais: 1]", key: "pzba" },
    { title: "11 (PZB28) [vecais: 2]", key: "pzb_resnie" },
    { title: "12 (PZB18) [vecais: 3]", key: "pzb_videjie" },
    { title: "13 (PZB14) [vecais: 4]", key: "pzb_tievie" },
    { title: "14 (PZB10)", key: "pzb10" },
    { title: "15 (PZBT)", key: "pzbt" },
    { title: "16 (PZB6)", key: "pzb6" },
    { title: "17 (PZB60)", key: "pzb60" },
    { title: "20 (EZBA) [vecais: 5]", key: "ezba" },
    { title: "21 (EZB28) [vecais: 6]", key: "ezb_resnie" },
    { title: "22 (EZB18) [vecais: 7]", key: "ezb_videjie" },
    { title: "23 (EZB14) [vecais: 8]", key: "ezb_tievie" },
    { title: "24 (EZB10)", key: "ezb10" },
    { title: "25 (EZBS)", key: "ezbs" },
    { title: "26 (EZB6)", key: "ezb6" },
    { title: "27 (EZB60)", key: "ezb60" },
    { title: "30 (BF) [vecais: 9]", key: "bf" },
    { title: "31 (BFC) [vecais: 19]", key: "bzb" },
    { title: "32 (BF3)", key: "bf3" },
    { title: "33 (BF4)", key: "bf4" },
    { title: "40 (LKT) [vecais: 10]", key: "lkt" },
    { title: "41 (LKT14)", key: "lkt14" },
    { title: "42 (LKT22)", key: "lkt22" },
    { title: "43 (LKTB)", key: "lktb" },
    { title: "44 (AZB) [vecais: 11]", key: "azb" },
    { title: "45 (MZB) [vecais: 12]", key: "mzb" },
    { title: "50 (SKT) [vecais: 13]", key: "skt" },
    { title: "51 (SKG) [vecais: 14]", key: "skg" },
    { title: "52 (SKGP)", key: "skgp" },
    { title: "53 (SKGE)", key: "skge" },
    { title: "54 (SKGPS)", key: "skgps" },
    { title: "55 (SKGES)", key: "skges" },
    { title: "60 (SKPM) [vecais: 15]", key: "skpm" },
    { title: "61 (BPM) [vecais: 16]", key: "bpm" },
    { title: "62 (APM) [vecais: 17]", key: "apm" },
    { title: "70 (M) [vecais: 18]", key: "m" },
    { title: "71 (SKM)", key: "skm" },
    { title: "72 (LKM)", key: "lkm" },
    { title: "73 (AM)", key: "am" },
    { title: "80 (OZB) [vecais: 20]", key: "ozb" },
    { title: "81 (OSZB)", key: "oszb" },
    { title: "82 (OZM)", key: "ozm" },
    { title: "83 (OSM)", key: "osm" },
    { title: "90 (NS) [vecais: 21]", key: "ns" },
];

export const STATES = [
    { text: "Plānotais fonds", value: 100000000 },
    { text: "Sagatavotais fonds", value: 100000001 },
    { text: "Izstrāde iesākta", value: 100000002 },
    { text: "Izstrāde pabeigta", value: 100000003 },
    { text: "Meža atjaunošana", value: 100000004 },
    { text: "Sadarbība noslēgta", value: 100000005 },
    { text: "Nepieciešams pārskats", value: 100000006 },
];

export const IMAGE_TYPES = {
    LADKARTE: "LADKARTE",
    LATBIO: "LATBIO",
    IPASUMS: "IPASUMS",
    KRAUTUVE: "KRAUTUVE",
    PEC_CIRSMA: "PEC_CIRSMA",
    PEC_FORWARD_CELI: "PEC_FORWARD_CELI",
    PEC_KRAUTUVE: "PEC_KRAUTUVE",
    PEC_KOKVEDEJ_CELI: "PEC_KOKVEDEJ_CELI",
    NOGABALI: "NOGABALI",
    KRAUTUVEKARTE: "KRAUTUVEKARTE",
    PIEBRAUKSANA: "PIEBRAUKSANA",
    CIRSMA: "CIRSMA",
};

export const IMAGE_CATEGORIES = [{
    id: null,
    label: "",
}, {
    id: "LADKARTE",
    label: "LAD Karte",
}, {
    id: "LATBIO",
    label: "Lat Bio",
}, {
    id: "IPASUMS",
    label: "Pirmais apmeklējums Īpašuma foto",
}, {
    id: "KRAUTUVE",
    label: "Izstrādes foto ar krautuvi",
}, {
    id: "PEC_CIRSMA",
    label: "Pēc izstrādes cirsmas foto",
}, {
    id: "PEC_FORWARD_CELI",
    label: "Pēc izstrādes pievešanas ceļu foto",
}, {
    id: "PEC_KRAUTUVE",
    label: "Pēc izstrādes krautuves foto",
}, {
    id: "PEC_KOKVEDEJ_CELI",
    label: "Pēc izstrādes aizvešanas ceļu foto",
}];
