import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";

import { NavLink, Redirect } from "react-router-dom";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import { HeaderMenu } from "../../components/HeaderMenu";
import { ImageUploader } from "../../components/ImageUploader";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { SelectField } from "../../components/SelectD";
import { IMAGE_CATEGORIES } from "../../utils/constants";
import { Helpers } from "../../utils/helpers";
import { PropertyAfterDevelopment } from "../Properties/types/property";

interface AfterDevelopmentState {
    redirect: boolean;
    loaded: boolean;
    data: PropertyAfterDevelopment;
    savingData: boolean;
    activeCategory: null | string;
}

interface AfterDevelopmentProps extends LayoutProps {
    match: any;
    form: any;
}

class AddImagesWrapped extends React.Component<AfterDevelopmentProps, AfterDevelopmentState> {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loaded: false,
            data: {} as any,
            savingData: false,
            activeCategory: null,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/information`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                data: response.data.data || null,
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        })

    changeActiveCategory = (e) => this.setState({ activeCategory: e.target.value });

    render() {
        const propertyId = this.props.match.params.propertyId;
        const { getFieldProps } = this.props.form;
        const { data, activeCategory } = this.state;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}`}/>;
        }

        const loadedPageContent =
            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Kategorija</label>
                                <SelectField
                                    options={IMAGE_CATEGORIES}
                                    {...getFieldProps("kategorija", {
                                        onChange: this.changeActiveCategory,
                                        initialValue: activeCategory,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <div className="buttons--row">
                                <ResponsiveElement
                                    desktopProps={{ size: "large", className: "ui large button" }}
                                    mobileProps={{ style: { width: "100%" }, className: "ui huge button"}}
                                >
                                    <a href={`https://karte.lad.gov.lv/?q=${data.cadastre_number}`} target="_blank">LAD karte</a>
                                </ResponsiveElement>
                            </div>
                        </Grid.Row>

                        {activeCategory && <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Foto</label>
                                <ImageUploader
                                    type={activeCategory}
                                    propertyId={this.props.match.params.propertyId}
                                    cadastreNumber={data.cadastre_number}
                                />
                            </Form.Field>
                        </Grid.Row>}
                    </Grid>
                </Form>
            </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        const backButton = <NavLink to={`/ipasums/${this.props.match.params.propertyId}`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                 <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Pievienot foto
                                        <Header.Subheader>{data && data.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Pievienot foto
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> {data && data.felling_area_name}
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}

export const AddImages = createForm()(AddImagesWrapped as any);
