
import * as React from "react";
import { Icon } from "semantic-ui-react";
import { InlineEditProps, InlineEditState } from "./types/inlineEdit.type";

export class InlineEdit extends React.Component<InlineEditProps, InlineEditState> {

    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.initVisible,
        };
    }

    makeVisible = () => {
        this.setState({
            visible: true,
        });
    }

    visibiltyStyle = (): any => {
        if (this.state.visible) {
            return {
                visibility: "visible",
                height: "auto",
            };
        }

        return {
            visibility: "hidden",
            height: 0,
        };
    }

    render() {
        return (
            <React.Fragment>
                <div style={this.visibiltyStyle()}>
                    {this.props.input}
                </div>
                <div style={{ display: !this.state.visible ? "block" : "none" }}>
                    {this.props.label} <Icon name="edit" onClick={this.makeVisible} style={{ cursor: "pointer" }}/>
                </div>
            </React.Fragment>
        );
    }
}
