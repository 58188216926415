import * as React from "react";
import cookie from "react-cookies";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AddImages } from "../../containers/AddImages";
import { AddPropertyInformation } from "../../containers/AddPropertyInformation";
import { AddPropertyPartition } from "../../containers/AddPropertyPartition";
import { AfterDevelopment } from "../../containers/AfterDevelopment";
import { AllHabitatsForm } from "../../containers/AllHabitatsForm";
import { ApproachForm } from "../../containers/ApproachForm";
import { BeforeDevelopment } from "../../containers/BeforeDevelopment";
import { ConveyorStatus } from "../../containers/ConveyorStatus";
import { CutCubicMeters } from "../../containers/CutCubicMeters";
import { DevelopmentStatus } from "../../containers/DevelopmentStatus";
import { EditPropertyPartition } from "../../containers/EditPropertyPartition";
import { FirstVisit } from "../../containers/FirstVisit";
import { HabitatsForm } from "../../containers/HabitatsForm";
import { Inspection } from "../../containers/Inspection";
import { JobTasks } from "../../containers/JobTask";
import { Properties } from "../../containers/Properties";
import { Property } from "../../containers/Property";
import { PropertyDevelopment } from "../../containers/PropertyDevelopment";
import { PropertyInfo } from "../../containers/PropertyInfo";
import { PropertyStackings } from "../../containers/PropertyStackings";
import { ResidueWarehouse } from "../../containers/ResidueWarehouse";
import { WorkSafety } from "../../containers/WorkSafety";
import { ActiveUser } from "../App";

export interface LayoutProps {
    activeUser: ActiveUser | null;
    resetAppState(): void;
    setActiveType(param: number): void;
    getActiveType(): number;
    showMobileMenu(): void;
    hideMobileMenu(): void;
    history: any[];
}

export class LayoutWrapper extends React.Component<LayoutProps, {}> {
    logout = () => {
        cookie.remove("ozols-forest-token");
        this.props.resetAppState();
    }

    render() {
        return (
            <div style={{ height: "100vh" }}>
                <Router>
                    <Switch>
                        <Route exact path="/" component={routeProps => <Properties {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/nogabals/:nogabals" component={routeProps => <EditPropertyPartition {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/pievienot_nogabalu" component={routeProps => <AddPropertyPartition {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/pirmais_apmeklejums" component={routeProps => <FirstVisit {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/apgaita" component={routeProps => <Inspection {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/biotopu_veidlapa/visas" component={routeProps => <AllHabitatsForm {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/biotopu_veidlapa" component={routeProps => <HabitatsForm {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/darba_drosiba" component={routeProps => <WorkSafety {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/pirms_izstrades" component={routeProps => <BeforeDevelopment {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/izstrade" component={routeProps => <PropertyDevelopment {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/pec_izstrades" component={routeProps => <AfterDevelopment {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/nozagetie_kubikmetri" component={routeProps => <CutCubicMeters {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/krautejumi" component={routeProps => <ResidueWarehouse {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/atlikums_krautuve" component={routeProps => <PropertyStackings {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/informacija" component={routeProps => <AddPropertyInformation {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/pievienot_foto" component={routeProps => <AddImages {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/info" component={routeProps => <PropertyInfo {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/izstrades_statusi/darba_uzdevumi" component={routeProps => <JobTasks {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/izstrades_statusi/piegajiens/:approach" component={routeProps => <ApproachForm {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/izstrades_statusi" component={routeProps => <DevelopmentStatus {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId/:action" component={routeProps => <Property {...this.props} {...routeProps}/>}/>
                        <Route path="/ipasums/:propertyId" component={routeProps => <Property {...this.props} {...routeProps}/>}/>

                        <Route path="/pievedeju_statuss" component={routeProps => <ConveyorStatus {...this.props} {...routeProps}/>}/>
                    </Switch>
                </Router>
            </div>
        );
    }
}
