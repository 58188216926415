import * as React from "react";
import cookie from "react-cookies";
import { Authorization } from "../../containers/Authorization";
import { FirstVisitAcknowledgment } from "../../containers/FirstVisitAcknowledgment";
import { Helpers } from "../../utils/helpers";
import { FatalError } from "../Error/Fatal";
import { ErrorBoundary } from "../ErrorBoundry";
import { LayoutWrapper } from "../LayoutWrapper";
import { heartBeat } from "../SessionCheck";

interface ApplicationState {
    isAuthorized: boolean;
    initHeartbeat: boolean;
    activeUser: ActiveUser | null;
    haveError: boolean;
    activeType: number;
    loading: boolean;
}

export interface ActiveUser {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    accessType: number | null;
}

export class App extends React.Component<{}, ApplicationState> {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            isAuthorized: false,
            activeUser: null,
            initHeartbeat: false,
            haveError: false,
            activeType: 0,
        };
    }

    componentDidCatch() {
        this.setState({
            haveError: true,
        });
    }

    componentWillMount() {
        // Check if user have already logged in by checking session cookie
        // If cookie exist send it to backend to check if it's valid
        // If it's valid set use as authorized and skip login form
        heartBeat(cookie.load("ozols-forest-token"))
            .then(response => {
                this.setState({
                    isAuthorized: response.success,
                    initHeartbeat: true,
                    activeUser: response.success ? response.data : null,
                    activeType: response.success ? this.checkActiveType(response.data.accessType) : 0,
                });
            });
    }

    componentDidMount() {
        // For some reason semantic ui loads all styles with delay
        // Aded timeout, so page dont look like shit
        setTimeout(() => this.setState({ loading: false }), 1500);
    }

    resetAppState = () => {
        this.setState({
            isAuthorized: false,
            activeUser: null,
        });
    }

    checkActiveType = (activeType) => {
        if (!activeType) {
            return 0;
        }

        if (activeType === 100000002) {
            return 100000000;
        }

        return activeType;
    }

    /**
     * Hook to check if user has been authorized
     * This will be passed to children component
     */
    isAuthorizedCallback = (data: any) => {
        this.setState({
            isAuthorized: true,
            activeUser: data,
            activeType: this.checkActiveType(data.accessType),
        });
    }

    setActiveType = (type: number) => this.setState({
        activeType: type,
    })

    getActiveType = () => this.state.activeType;

    render() {
        const { loading, haveError, initHeartbeat, isAuthorized, activeUser } = this.state;

        if (loading) {
            return null;
        }

        if (window.location.href.indexOf("pirmais_apmeklejums/apliecinajums") > -1) {
            return (<FirstVisitAcknowledgment/>);
        }

        if (haveError) {
            return FatalError;
        } else if (!initHeartbeat) {
            // Check if we checked initial heartbeat
             // This is used to know which page should be shown as first
            return null;
        } else if (!isAuthorized) {
            // Check if user is authorized
            Helpers.rootPageUrl();
            return (<Authorization isAuthorized={this.isAuthorizedCallback}/>);
        }

        return <ErrorBoundary>
            <LayoutWrapper
                activeUser={activeUser}
                resetAppState={this.resetAppState}
                setActiveType={this.setActiveType}
                getActiveType={this.getActiveType}
            />
        </ErrorBoundary>;
    }
}
