export const REMOVAL_TYPES = [{
    id: "",
    label: "",
}, {
    id: "Tuvu pie ceļa (0-200m)",
    label: "Tuvu pie ceļa (0-200m)",
}, {
    id: "Vidēji tālu no ceļa (500-1000m)",
    label: "Vidēji tālu no ceļa (500-1000m)",
}, {
    id: "Tālu no ceļa (Vairāk par 1000m)",
    label: "Tālu no ceļa (Vairāk par 1000m)",
}, {
    id: "0-200m cauri kaimiņiem",
    label: "0-200m cauri kaimiņiem",
}, {
    id: "200-500m cauri kaimiņiem",
    label: "200-500m cauri kaimiņiem",
}, {
    id: "500-1000m cauri kaimiņiem",
    label: "500-1000m cauri kaimiņiem",
}, {
    id: "Vairāk par 1000m cauri kaimiņiem",
    label: "Vairāk par 1000m cauri kaimiņiem",
}, {
    id: "Cauri vairākiem kaimiņiem (0-500m)",
    label: "Cauri vairākiem kaimiņiem (0-500m)",
}, {
    id: "Cauri vairākiem kaimiņiem (500m<)",
    label: "Cauri vairākiem kaimiņiem (500m<)",
}];

export const SIMPLE_OPTIONS = [{
    id: "",
    label: "",
}, {
    id: "Ir",
    label: "Ir",
}, {
    id: "Nav",
    label: "Nav",
}, {
    id: "Nevajag",
    label: "Nevajag",
}];

export const IZSTRADES_VEIDI = [{
    id: "",
    label: "",
}, {
    id: "Harvesters Lielais",
    label: "Harvesters Lielais",
}, {
    id: "Harvesters Vidējais",
    label: "Harvesters Vidējais",
}, {
    id: "Harvesters Mazais",
    label: "Harvesters Mazais",
}, {
    id: "Rokaszāģi",
    label: "Rokaszāģi",
}, {
    id: "Harvesters + Rokaszāģi",
    label: "Harvesters + Rokaszāģi",
}];

export const TEST_STATE = [{
    id: "",
    label: "",
}, {
    id: "Izpildīta",
    label: "Izpildīta",
}, {
    id: "Jāpilda",
    label: "Jāpilda",
}, {
    id: "Nevajag",
    label: "Nevajag",
}];

export const MEZA_APSTAKLI = [{
    id: "",
    label: "",
}, {
    id: 111110000,
    label: "Sauss",
}, {
    id: 111110001,
    label: "Slapjš",
}];

export const IZSTRADES_APSTAKLI = [{
    id: "",
    label: "",
}, {
    id: "Ziema",
    label: "Ziema",
}, {
    id: "Pavasaris",
    label: "Pavasaris",
}, {
    id: "Vasara",
    label: "Vasara",
}, {
    id: "Rudens",
    label: "Rudens",
}, {
    id: "Visu gadu",
    label: "Visu gadu",
}];

export const PAMEZS_OPTIONS = [{
    id: "",
    label: "",
}, {
    id: "Biezs",
    label: "Biezs",
}, {
    id: "Vidējs",
    label: "Vidējs",
}, {
    id: "Neliels",
    label: "Neliels",
}];

export const TEST_STATE_2 = [{
    id: "",
    label: "",
}, {
    id: "Labi",
    label: "Labi",
}, {
    id: "Slikti",
    label: "Slikti",
}];

export const CELA_ZIME_VALUES = [{
    id: 100000000,
    label: "Nav",
}, {
    id: 100000001,
    label: "Ir",
}];

export const BOOLEAN_VALUES = [{
    id: "",
    label: "",
}, {
    id: "Jā",
    label: "Jā",
}, {
    id: "Nē",
    label: "Nē",
}];

export const BOOLEAN_VALUES_CERTAMIE = [{
    id: "",
    label: "",
}, {
    id: "Jā",
    label: "Jā",
}, {
    id: "Nē",
    label: "Nē",
}, {
    id: "Nav nepieciešams",
    label: "Nav nepieciešams",
}];

export const CONDTION_VALUES = [{
    id: "",
    label: "",
}, {
    id: "Labs",
    label: "Labs",
}, {
    id: "Vidējs",
    label: "Vidējs",
}, {
    id: "Slikts",
    label: "Slikts",
}];

export const AVIABLITY_VALUES = [{
    id: "",
    label: "",
}, {
    id: "Pieejams",
    label: "Pieejams",
}, {
    id: "Nav Pieejams",
    label: "Nav Pieejams",
}];
