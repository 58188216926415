
export const new_piegajiensValues = {
    100000000: "Pirmais",
    100000001: "Otrais",
    100000002: "Trešais",
    111110000: "Ceturtais",
    111110001: "Piektais",
};

export const new_stadijaValues = {
    100000000:	"Plānotais",
    100000001:	"Sagatavotais",
    100000002:	"Gatavais",
    100000003:	"Aktīvais",
    100000004:	"Iepauzēts",
    100000005:	"Noslēgts",
};
