import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";

import { NavLink, Redirect } from "react-router-dom";
import { Table } from "semantic-ui-react";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { Helpers } from "../../utils/helpers";
import { PropertyAfterDevelopment } from "../Properties/types/property";

interface AfterDevelopmentState {
    redirect: boolean;
    loaded: boolean;
    data: PropertyAfterDevelopment;
    savingData: boolean;
    activeCategory: null | string;
}

interface AfterDevelopmentProps extends LayoutProps {
    match: any;
    form: any;
}

class PropertyInfoWrapped extends React.Component<AfterDevelopmentProps, AfterDevelopmentState> {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loaded: false,
            data: {} as any,
            savingData: false,
            activeCategory: null,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/information`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                data: response.data.data || null,
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        })

    changeActiveCategory = (e) => this.setState({ activeCategory: e.target.value });

    formatBoolean = (item) => {
        if (!item) {
            return "";
        }

        return item ? "Jā" : "Nē";
    }

    formatCaurmers = (item) => {
        if (!item) {
            return "";
        }

        if (item === 100000000) {
            return "Ir";
        }

        if (item === 100000001) {
            return "Būs";
        }

        if (item === 100000002) {
            return "Nav";
        }

        return item;
    }

    CAstate = (property) => {
        switch (property.new_cirsanasapliecinajums) {
            case 100000000:
                return "Nav";
            case 100000001:
                return "Iesniegts";
            case 100000002:
                return "Iegūts";
            default:
                return null;
        }
    }

    ownerMaterialsStatus = (property) => {
        switch (property.new_saimniekamatstatsmaterials) {
            case 100000000:
                return "Ir";
            case 100000003:
                return "Vajag atstāt";
            case 100000002:
                return "Nevajag atstāt";
            default:
                return null;
        }
    }

    render() {
        const propertyId = this.props.match.params.propertyId;
        const { data } = this.state;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}`}/>;
        }

        const loadedPageContent =
            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
                <Table basic="very" unstackable style={{ background: "#fff", padding: "10px 20px" }}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Kadastra numurs</Table.Cell>
                            <Table.Cell>{data.cadastre_number}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Stadija</Table.Cell>
                            <Table.Cell>{Helpers.getState(data.state)}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Caurmērs</Table.Cell>
                            <Table.Cell>{this.formatCaurmers(data.caurmers)}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Specifiskas izstrādes vajadzības</Table.Cell>
                            <Table.Cell>{data.izstrades_prasibas}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Klients vēlas tikties dabā</Table.Cell>
                            <Table.Cell>
                                {this.formatBoolean(data.saimnieksvelastiktiesdaba)}
                                {data.saimnieksvelastiktiesdaba && data.saimnieksvelastiktiesdaba_comment && <blockquote>
                                    {data.saimnieksvelastiktiesdaba_comment}
                                </blockquote>}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>CA Statuss</Table.Cell>
                            <Table.Cell>{this.CAstate(data)}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>CA Komentārs</Table.Cell>
                            <Table.Cell>{data.new_cirsanasapliecinajums_comment}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Saimniekam atstāts materiāls </Table.Cell>
                            <Table.Cell>{this.ownerMaterialsStatus(data)}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Komentārs par materiālu</Table.Cell>
                            <Table.Cell>{data.new_saimniekamatstatsmaterials_comment}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Kontaktpersona</Table.Cell>
                            <Table.Cell>{data.contacts && <div>
                                <b>{data.contacts.firstname}</b> - <a href={`tel:${data.contacts.mobilephone}`}>{data.contacts.mobilephone}</a>
                            </div>}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        const backButton = <NavLink to={`/ipasums/${this.props.match.params.propertyId}`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                 <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Īpašuma informācija
                                        <Header.Subheader>{data && data.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Īpašuma informācija
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> {data && data.felling_area_name}
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}

export const PropertyInfo = createForm()(PropertyInfoWrapped as any);
