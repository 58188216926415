import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { Button, Divider, Form, Loader } from "semantic-ui-react";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import { HeaderMenu } from "../../components/HeaderMenu";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { Helpers } from "../../utils/helpers";

interface DevelopmentStatusMainProps {
    match: any;
    form: any;
}

class InspectionMain extends React.Component<DevelopmentStatusMainProps, any> {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loaded: false,
            approach: {} as any,
            property: {} as any,
            savingData: false,
            completeSection: false,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    fetchPropertyData = () => {
        const propertyId = this.props.match.params.propertyId;

        axios.get(`${process.env.baseUrl}/api/properties/${propertyId}/insepction`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                property: response.data.property || {},
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        });
    }

    saveChanges = () => {
        const propertyId = this.props.match.params.propertyId;

        this.props.form.validateFields((error, values) => {
            if (error) {
                Helpers.showError("Neizdevās saglabāt datus");
            }

            this.setState({ savingData: true }, () => {
                axios.post(`${process.env.baseUrl}/api/properties/${propertyId}/insepction`, {
                    ...values,
                }, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                .then(response => {
                    if (response.data.success) {
                        this.setState({ savingData: false, redirect: true });
                    } else {
                        this.setState({ savingData: false });
                        Helpers.showError("Neizdevās saglabāt datus");
                    }
                })
                .catch(_error => {
                    this.setState({ savingData: false});
                    Helpers.showError("Neizdevās saglabāt datus");
                });
            });
        });
    }

    // tslint:disable-next-line:cyclomatic-complexity
    render() {
        const { property, approach } = this.state;
        const propertyId = this.props.match.params.propertyId;
        const { getFieldProps } = this.props.form;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}/ipasuma_apmeklejums`}/>;
        }

        const loadedPageContent =
            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Apgaitas mērķis</label>
                                <textarea
                                    {...getFieldProps("apgaitas_merkis", {
                                        initialValue: approach.apgaitas_merkis || "",
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Apgaitas komentārs</label>
                                <textarea
                                    {...getFieldProps("apgaitas_komentars", {
                                        initialValue: approach.apgaitas_komentars || "",
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                            <Divider />
                        </Responsive>

                        <Grid.Row >
                            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" } }}>
                                <Button onClick={this.saveChanges} color="green" loading={this.state.savingData}>
                                    <Icon name="save"/>
                                    Saglabāt
                                </Button>
                            </ResponsiveElement>
                        </Grid.Row>
                    </Grid>
                </Form>
            </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        const backButton = <NavLink to={`/ipasums/${this.props.match.params.propertyId}/ipasuma_apmeklejums`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Apgaita
                                        <Header.Subheader>{property && property.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Apgaita
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}/ipasuma_apmeklejums`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> {property && property.felling_area_name}
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}

export const Inspection = createForm()(withRouter(InspectionMain as any));
