import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { Message } from "semantic-ui-react";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";

import { HeaderMenu } from "../../components/HeaderMenu";
import { ImageUploader } from "../../components/ImageUploader";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { SelectField } from "../../components/SelectD";
import {
    BOOLEAN_VALUES,
    IZSTRADES_APSTAKLI,
    IZSTRADES_VEIDI,
    MEZA_APSTAKLI,
    PAMEZS_OPTIONS,
    REMOVAL_TYPES,
    TEST_STATE,
    TEST_STATE_2,
} from "../../constants/constants";
import { IMAGE_TYPES } from "../../utils/constants";
import { Helpers } from "../../utils/helpers";
import { PropertyFirstVisit } from "../Properties/types/property";

interface FirstVisitState {
    redirect: boolean;
    loaded: boolean;
    data: PropertyFirstVisit;
    savingData: boolean;
    completeSection: boolean;
    deletingFile: null | string;
    imageUploading: boolean;
    imageUploadProgress: number;
}

interface FirstVisitProps extends LayoutProps {
    match: any;
    form: any;
}

class FirstVisitMain extends React.Component<FirstVisitProps, FirstVisitState> {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loaded: false,
            data: {} as any,
            savingData: false,
            completeSection: false,
            deletingFile: null,
            imageUploading: false,
            imageUploadProgress: 0,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/first_visit`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                data: response.data.data || null,
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        })

    visitHabitatsForm = () => {
        const propertyId = this.props.match.params.propertyId;

        // Save data before leaving page
        axios.put(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/first_visit`, {
            ...this.state.data,
        }, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            if (!response.data.success) {
                Helpers.showError("Neizdevās saglabāt datus");
            }
        })
        .catch(_error => {
            Helpers.showError("Neizdevās saglabāt datus");
        });

        this.props.history.push(`/ipasums/${propertyId}/biotopu_veidlapa`);
    }

    completeChanges = () => this.processData(true);
    saveChanges = () => this.processData();
    processData = (completed = false) => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                Helpers.showError("Neizdevās saglabāt datus");
            }

            this.setState({ savingData: true }, () => {
                axios.put(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/first_visit`, {
                    ...values,
                    ...(completed && { completed: true }),
                }, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                .then(response => {
                    if (response.data.success) {
                        this.setState({ savingData: false, redirect: true });
                    } else {
                        this.setState({ savingData: false });
                        Helpers.showError("Neizdevās saglabāt datus");
                    }
                })
                .catch(_error => {
                    this.setState({ savingData: false });
                    Helpers.showError("Neizdevās saglabāt datus");
                });
            });
        });
    }

    // tslint:disable-next-line:cyclomatic-complexity
    render() {
        const { getFieldProps } = this.props.form;
        const propertyId = this.props.match.params.propertyId;
        const data = this.state.data;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}/ipasuma_apmeklejums`}/>;
        }

        const loadedPageContent =
            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
                <Form>
                    <Grid>
                        {this.state.data.first_visit_completed && <Grid.Row>
                            <Message warning style={{ display: "block", width: "100%", textAlign: "center" }}>
                                <Message.Header>NODOTS!</Message.Header>
                            </Message>
                        </Grid.Row>}

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Izstrādes veids</label>
                                <SelectField
                                    options={IZSTRADES_VEIDI}
                                    {...getFieldProps("izstrades_veids", {
                                        initialValue: data.izstrades_veids || IZSTRADES_VEIDI[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Izstrādātājs</label>
                                <input
                                    maxlength="100"
                                    {...getFieldProps("new_izstradatajs", {
                                        initialValue: data.new_izstradatajs || "",
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Izvedējs</label>
                                <input
                                    maxlength="100"
                                    {...getFieldProps("new_izvedejs", {
                                        initialValue: data.new_izvedejs || "",
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Izstrādes apstākļi</label>
                                <SelectField
                                    options={IZSTRADES_APSTAKLI}
                                    {...getFieldProps("izstrades_apstakli", {
                                        initialValue: data.izstrades_apstakli || IZSTRADES_APSTAKLI[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Meža apstākļi</label>
                                <SelectField
                                    options={MEZA_APSTAKLI}
                                    {...getFieldProps("meza_apstakli", {
                                        initialValue: data.meza_apstakli || MEZA_APSTAKLI[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Krautuvju skaits</label>
                                <input
                                    {...getFieldProps("krautuvju_skaits", {
                                        initialValue: data.krautuvju_skaits || "",
                                    })}
                                    type="number"
                                    pattern="[0-9]*"
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Pamežs</label>
                                <SelectField
                                    options={PAMEZS_OPTIONS}
                                    {...getFieldProps("pamezs", {
                                        initialValue: data.pamezs || PAMEZS_OPTIONS[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Pievešanas apstākļi</label>
                                <SelectField
                                    options={REMOVAL_TYPES}
                                    {...getFieldProps("pievesanas_apstakli", {
                                        initialValue: data.pievesanas_apstakli || REMOVAL_TYPES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Pievešanas ceļš iezīmēts kartē</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("piev_cels_iezimets", {
                                        initialValue: data.piev_cels_iezimets || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Krautuves vieta kartē</label>
                                <ImageUploader
                                    type={IMAGE_TYPES.KRAUTUVEKARTE}
                                    propertyId={this.props.match.params.propertyId}
                                    cadastreNumber={data.cadastre_number}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Piebraukšanas vieta kartē</label>
                                <ImageUploader
                                    type={IMAGE_TYPES.PIEBRAUKSANA}
                                    propertyId={this.props.match.params.propertyId}
                                    cadastreNumber={data.cadastre_number}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Komentārs šoferim</label>
                                <textarea
                                    {...getFieldProps("komentars_soferim", {
                                        initialValue: data.papildus_izmaksas || "",
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Biotopu anketa</label>
                                <div className="buttons--row">
                                    <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" }}}>
                                        <Button onClick={this.visitHabitatsForm}>Apskatīt anketu</Button>
                                    </ResponsiveElement>
                                </div>
                                <div className="buttons--row">
                                    <ResponsiveElement
                                        desktopProps={{ size: "large", className: "ui large button" }}
                                        mobileProps={{ style: { width: "100%" }, className: "ui huge button"}}
                                    >
                                        <a href="http://latbio.lv/MBI/search_db" target="_blank">LatBio</a>
                                    </ResponsiveElement>
                                </div>
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Biotopu anketa statuss</label>
                                <SelectField
                                    options={TEST_STATE}
                                    {...getFieldProps("anketas_statuss", {
                                        initialValue: data.anketas_statuss || TEST_STATE[2].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Papildus izmaksas</label>
                                <textarea
                                    {...getFieldProps("papildus_izmaksas", {
                                        initialValue: data.papildus_izmaksas || "",
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Specifiski sortimenti, kuri jāgatavo mežā</label>
                                <textarea
                                    {...getFieldProps("specifiski_sortimenti", {
                                        initialValue: data.specifiski_sortimenti || "",
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Aizdomas par šķembām</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("aizdom_skemb", {
                                        initialValue: data.aizdom_skemb || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Stigošanas kvalitāte</label>
                                <SelectField
                                    options={TEST_STATE_2}
                                    {...getFieldProps("stigosanas_kvalitate", {
                                        initialValue: data.stigosanas_kvalitate || TEST_STATE_2[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Robežu kvalitāte</label>
                                <SelectField
                                    options={TEST_STATE_2}
                                    {...getFieldProps("robez_kvalitate", {
                                        initialValue: data.robez_kvalitate || TEST_STATE_2[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        {!this.state.data.first_visit_completed && <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Īpašuma foto</label>

                                <ImageUploader
                                    type={IMAGE_TYPES.IPASUMS}
                                    propertyId={this.props.match.params.propertyId}
                                    cadastreNumber={data.cadastre_number}
                                />

                            </Form.Field>
                        </Grid.Row>}

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Piezīmes</label>
                                <textarea
                                    {...getFieldProps("piezimes", {
                                        initialValue: data.piezimes || "",
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                            <Divider />
                        </Responsive>

                        {!this.state.data.first_visit_completed && <Grid.Row>
                            {!this.state.completeSection && <div className="buttons--row">
                                <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" }}}>
                                    <Button onClick={this.saveChanges} color="green" loading={this.state.savingData}>
                                        <Icon name="save"/>
                                        Saglabāt
                                    </Button>
                                </ResponsiveElement>
                            </div>}
                            {!this.state.savingData && <div className="buttons--row">
                                <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" }}}>
                                    <Button onClick={this.completeChanges} loading={this.state.completeSection}>
                                        <Icon name="check"/>
                                        Nodot
                                    </Button>
                                </ResponsiveElement>
                            </div>}
                        </Grid.Row>}
                    </Grid>
                </Form>
            </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        const backButton = <NavLink to={`/ipasums/${this.props.match.params.propertyId}/ipasuma_apmeklejums`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                 <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Pirmais apmeklējums
                                        <Header.Subheader>{data && data.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Pirmais apmeklejums
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}/ipasuma_apmeklejums`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> {data && data.felling_area_name}
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}

export const FirstVisit = createForm()(withRouter(FirstVisitMain as any));
