import axios from "axios";
import Upload from "rc-upload";
import * as React from "react";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";

import { Button, Progress } from "semantic-ui-react";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { Helpers } from "../../utils/helpers";
import { PropertyPartition } from "../Properties/types/property";

interface FirstVisitState {
    loaded: boolean;
    fellingAraId: number;
    propertyData: PropertyPartition;
    deletingFile: null | string;
    imageUploading: boolean;
    imageUploadProgress: number;
    files: any[];
    finished: boolean;
}

class FirstVisitAcknowledgmentMain extends React.Component<LayoutProps, FirstVisitState> {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            fellingAraId: +window.location.pathname.split("/").slice(-1)[0],
            propertyData: {} as any,
            deletingFile: null,
            imageUploading: false,
            imageUploadProgress: 0,
            files: [],
            finished: false,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.state.fellingAraId}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                propertyData: response.data.data,
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        })

    // tslint:disable-next-line:cyclomatic-complexity
    render() {
        // tslint:disable-next-line:no-this-assignment
        const self = this;

        if (!this.state.fellingAraId) {
            alert("Kaut kas nogāja greizi");
            return null;
        }

        const uploadProps = {
            name: "file",
            multiple: true,
            action: `${process.env.baseUrl}/api/first_fisit/acknowledgment/file`,
            headers: { Authorization: Helpers.getSessionId() },
            data: {
                felling_area_id: this.state.propertyData.id,
                cadastreNumber: this.state.propertyData.cadastre_number,
            },
            onProgress(step) {
                self.setState({ imageUploadProgress: step.percent });
            },
            onSuccess(response) {
                if (response.success) {
                    const tempFiles = [ ...self.state.files ];

                    tempFiles.push({
                        id: response.id,
                        image_url: response.filename,
                    });

                    self.setState({ files: tempFiles, imageUploading: false, finished: true });
                } else {
                    self.setState({ imageUploading: false, finished: false });
                    Helpers.showError("Neizdevās augšupielādēt failu!");
                }
            },
            beforeUpload() {
                self.setState({
                    imageUploading: true,
                });
            },
        };

        const loadedPageContent =
            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <h3>Augšupielādēt Ciršanas Apliecinājumu</h3>
                        </Grid.Row>
                        <Grid.Row>
                            <ul style={{ margin: 0, padding: 0, listStyleType: "none" }}>
                                <li><b>Reference:</b> #{this.state.propertyData.id}</li>
                                <li><b>Īpašuma nosaukums:</b> {this.state.propertyData.felling_area_name}</li>
                                <li><b>Kadastra numurs:</b> {this.state.propertyData.cadastre_number}</li>
                            </ul>
                        </Grid.Row>
                        <Grid.Row>
                            <Upload {...uploadProps} ref="inner">
                                <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
                                    <Button
                                        icon="upload"
                                        label={{
                                            basic: true,
                                            content: "Izvēlēties failu",
                                        }}
                                        labelPosition="right"
                                    />
                                </ResponsiveElement>
                            </Upload>

                            <div style={{ float: "left", width: "100%" }}>
                                {this.state.imageUploading && <div style={{ marginTop: 15 }}>
                                    <Progress percent={this.state.imageUploadProgress} indicating progress/>
                                </div>}
                            </div>

                            {this.state.finished && !this.state.imageUploading  && <div style={{ marginTop: 15 }}>
                                <b style={{ color: "#27ae60" }}>Fails veiksmīgi augšupielādēts!</b>
                            </div>}
                        </Grid.Row>
                    </Grid>
                </Form>
            </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        return (
            <div className="layout-wrapper">
                {pageContent}
            </div>
        );
    }
}

export const FirstVisitAcknowledgment = FirstVisitAcknowledgmentMain;
