import * as React from "react";
import { NavLink } from "react-router-dom";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";

interface PropertyProps extends LayoutProps {
    match: any;
}

export class ConveyorStatus extends React.Component<PropertyProps, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        const backButton = <NavLink to={"/"} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Visi īpašumi
        </NavLink>;

        const pageContent = <div style={{ background: "#fff", padding: "10px 15px" }}>
            <Table basic="very" unstackable>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Šodien</Table.HeaderCell>
                    <Table.HeaderCell>Vakar</Table.HeaderCell>
                    <Table.HeaderCell>Pēdējoreiz</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            MK002
                        </Table.Cell>
                        <Table.Cell>
                            <Label color="green" horizontal>
                                OK
                            </Label>
                        </Table.Cell>
                        <Table.Cell>
                            <Label color="green" horizontal>
                                OK
                            </Label>
                        </Table.Cell>
                        <Table.Cell>
                            09/11
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            MK003
                        </Table.Cell>
                        <Table.Cell>
                            <Label color="red" horizontal>
                                X
                            </Label>
                        </Table.Cell>
                        <Table.Cell>
                            <Label color="green" horizontal>
                                OK
                            </Label>
                        </Table.Cell>
                        <Table.Cell>
                            09/12
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            MK004
                        </Table.Cell>
                        <Table.Cell>
                            <Label color="green" horizontal>
                                OK
                            </Label>
                        </Table.Cell>
                        <Table.Cell>
                            <Label color="red" horizontal>
                                X
                            </Label>
                        </Table.Cell>
                        <Table.Cell>
                            09/11
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Pievedēju statuss
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Pievedēju statuss
                                        <Header.Subheader>
                                            <NavLink to={"/"} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> Visi īpašumi
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}
