import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";

import { NavLink, Redirect } from "react-router-dom";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import { HeaderMenu } from "../../components/HeaderMenu";
import { ImageUploader } from "../../components/ImageUploader";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { SelectField } from "../../components/SelectD";
import { BOOLEAN_VALUES } from "../../constants/constants";
import { IMAGE_TYPES } from "../../utils/constants";
import { Helpers } from "../../utils/helpers";
import { PropertyAfterDevelopment } from "../Properties/types/property";

interface AfterDevelopmentState {
    redirect: boolean;
    loaded: boolean;
    data: PropertyAfterDevelopment;
    savingData: boolean;
}

interface AfterDevelopmentProps extends LayoutProps {
    match: any;
    form: any;
}

class AfterDevelopmentWrapped extends React.Component<AfterDevelopmentProps, AfterDevelopmentState> {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loaded: false,
            data: {} as any,
            savingData: false,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/after_development`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                data: response.data.data || null,
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        })

    saveChanges = () => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                Helpers.showError("Neizdevās saglabāt datus");
            }

            this.setState({ savingData: true }, () => {
                axios.put(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/after_development`, {
                    ...values,
                }, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                .then(response => {
                    if (response.data.success) {
                        this.setState({ savingData: false, redirect: true });
                    } else {
                        this.setState({ savingData: false });
                        Helpers.showError("Neizdevās saglabāt datus");
                    }
                })
                .catch(_error => {
                    this.setState({ savingData: false });
                    Helpers.showError("Neizdevās saglabāt datus");
                });
            });
        });
    }

    onValueChange = (field, value) => {
        const tempData = { ...this.state.data };
        tempData[field] = value;

        this.setState({ data: tempData });
    }

    render() {
        const { getFieldProps } = this.props.form;
        const propertyId = this.props.match.params.propertyId;
        const data = this.state.data;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}`}/>;
        }

        const OPTIONS = [{
            id: "",
            label: "",
        }, {
            id: "Ir sakopta",
            label: "Ir sakopta",
        }, {
            id: "Jāsakopj",
            label: "Jāsakopj",
        }];

        const OPTIONS_2 = [{
            id: "",
            label: "",
        }, {
            id: "Nolīdzināti",
            label: "Nolīdzināti",
        }, {
            id: "Ir jālīdzina",
            label: "Ir jālīdzina",
        }, {
            id: "Līdzināšana nav nepieciešama",
            label: "Līdzināšana nav nepieciešama",
        }];

        const OPTIONS_3 = [{
            id: "",
            label: "",
        }, {
            id: "Ir novesta, sakopta",
            label: "Ir novesta, sakopta",
        }, {
            id: "Ir novesta, jāsakopj",
            label: "Ir novesta, jāsakopj",
        }, {
            id: "Nav novesta",
            label: "Nav novesta",
        }];

        const OPTIONS_4 = [{
            id: "",
            label: "",
        }, {
            id: "Ir sakopts",
            label: "Ir sakopts",
        }, {
            id: "Jāsakopj",
            label: "Jāsakopj",
        }];

        const loadedPageContent =
            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Cirsma</label>
                                <SelectField
                                    options={OPTIONS}
                                    {...getFieldProps("cirsma", {
                                        initialValue: data.cirsma || OPTIONS[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Cirsmas foto</label>
                                <ImageUploader
                                    type={IMAGE_TYPES.PEC_CIRSMA}
                                    propertyId={this.props.match.params.propertyId}
                                    cadastreNumber={data.cadastre_number}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Pievešanas ceļi</label>
                                <SelectField
                                    options={OPTIONS_2}
                                    {...getFieldProps("pievesanas_celi", {
                                        initialValue: data.pievesanas_celi || OPTIONS_2[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Pievešanas ceļi foto</label>
                                <ImageUploader
                                    type={IMAGE_TYPES.PEC_FORWARD_CELI}
                                    propertyId={this.props.match.params.propertyId}
                                    cadastreNumber={data.cadastre_number}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Krautuve</label>
                                <SelectField
                                    options={OPTIONS_3}
                                    {...getFieldProps("krautuve", {
                                        initialValue: data.krautuve || OPTIONS_3[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Krautuves foto</label>
                                <ImageUploader
                                    type={IMAGE_TYPES.PEC_KRAUTUVE}
                                    propertyId={this.props.match.params.propertyId}
                                    cadastreNumber={data.cadastre_number}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Aizvešanas ceļš</label>
                                <SelectField
                                    options={OPTIONS_4}
                                    {...getFieldProps("aizvesanas_cels", {
                                        initialValue: data.aizvesanas_cels || OPTIONS_4[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Aizvešanas ceļš foto</label>
                                <ImageUploader
                                    type={IMAGE_TYPES.PEC_KOKVEDEJ_CELI}
                                    propertyId={this.props.match.params.propertyId}
                                    cadastreNumber={data.cadastre_number}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Vai viss izstrādāts pēc CA?</label>
                                <SelectField
                                    options={BOOLEAN_VALUES}
                                    {...getFieldProps("pec_ca", {
                                        initialValue: data.pec_ca || BOOLEAN_VALUES[0].id,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Piezīmes</label>
                                <textarea
                                    {...getFieldProps("piezimes", {
                                        initialValue: data.piezimes || "",
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                            <Divider />
                        </Responsive>

                        <Grid.Row>
                            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" } }}>
                                <Button onClick={this.saveChanges} color="green" loading={this.state.savingData}>
                                    <Icon name="save"/>
                                    Saglabāt
                                </Button>
                            </ResponsiveElement>
                        </Grid.Row>
                    </Grid>
                </Form>
            </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        const backButton = <NavLink to={`/ipasums/${this.props.match.params.propertyId}/ipasuma_apmeklejums`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                 <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Pēc izstrādes
                                        <Header.Subheader>{data && data.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Pēc izstrādes
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}/ipasuma_apmeklejums`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> {data && data.felling_area_name}
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}

export const AfterDevelopment = createForm()(AfterDevelopmentWrapped as any);
