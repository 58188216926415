import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import { HeaderMenu } from "../../components/HeaderMenu";
import { Helpers } from "../../utils/helpers";
import { new_piegajiensValues, new_stadijaValues } from "./helpers/helpers";
import { DevelopmentStatusState } from "./types/developmentStatus.type";

interface DevelopmentStatusMainProps {
    match: any;
    form: any;
}

class DevelopmentStatusMain extends React.Component<DevelopmentStatusMainProps, DevelopmentStatusState> {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loaded: false,
            data: [] as any,
            property: {} as any,
            savingData: false,
            completeSection: false,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/development_status`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                data: response.data.data.sort((a, b) => a.new_piegajiens - b.new_piegajiens) || [],
                property: response.data.property || {},
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        })

    render() {
        const { property } = this.state;
        const propertyId = this.props.match.params.propertyId;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}`}/>;
        }

        const backButton = <NavLink to={`/ipasums/${this.props.match.params.propertyId}`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Izstrades statusi
                                        <Header.Subheader>{property && property.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Izstrades statusi
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> {property && property.felling_area_name}
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    <div className="list-box list">
                        <div className="list-box-inner">
                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}/izstrades_statusi/darba_uzdevumi`} className="segment-with-navlink">
                                Darba uzdevumi
                            </NavLink>
                        </div>
                    </div>

                    {this.state.data.map(data => (
                        <div className="list-box list">
                            <div className="list-box-inner">
                                <NavLink to={`/ipasums/${this.props.match.params.propertyId}/izstrades_statusi/piegajiens/${data.id}`} className="segment-with-navlink">
                                    {new_piegajiensValues[data.new_piegajiens]} piegājiens - {new_stadijaValues[data.new_stadija]}
                                </NavLink>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export const DevelopmentStatus = createForm()(withRouter(DevelopmentStatusMain as any));
