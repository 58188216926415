import * as React from "react";

export const getPropertyActions = (requirement: string | null = null) => PropertyActions.filter(action => action.req === requirement);

const PropertyActions = [{
    id: "ipasuma_apmeklejums",
    link: (property) => `/ipasums/${property.id}/ipasuma_apmeklejums`,
    label: () => "Īpašuma apmeklējums",
    req: null,
    layout: "grid_view",
}, {
    id: "apjoms_ipasuma",
    link: (property) => `/ipasums/${property.id}/apjoms_ipasuma`,
    label: () => "Apjoms īpašumā",
    req: null,
    layout: "grid_view",
}, {
    id: "izstrades_statusi",
    link: (property) => `/ipasums/${property.id}/izstrades_statusi`,
    label: () => "Izstrādes statusi",
    req: null,
    layout: "grid_view",
}, {
    id: "citi",
    link: (property) => `/ipasums/${property.id}/citi`,
    label: () => "Citi",
    req: null,
    layout: "grid_view",
}, {
    id: "pirmais_apmeklejums",
    link: (property) => `/ipasums/${property.id}/pirmais_apmeklejums`,
    label: (property) => `Pirmais apmeklējums ${property && property.first_visit_completed && "(Nodots)" || ""}`,
    req: "ipasuma_apmeklejums",
    layout: "list",
}, {
    id: "izstrade",
    link: (property) => `/ipasums/${property.id}/izstrade`,
    label: () => "Izstrāde",
    req: "ipasuma_apmeklejums",
    layout: "list",
}, {
    id: "pec_izstrades",
    link: (property) => `/ipasums/${property.id}/pec_izstrades`,
    label: () => "Pēc izstrādes",
    req: "ipasuma_apmeklejums",
    layout: "list",
}, {
    id: "apgaita",
    link: (property) => `/ipasums/${property.id}/apgaita`,
    label: () => "Apgaita",
    req: "ipasuma_apmeklejums",
    layout: "list",
}, {
    id: "nozagetie_kubikmetri",
    link: (property) => `/ipasums/${property.id}/nozagetie_kubikmetri`,
    label: () => "Nozāģētie kubikmetri",
    req: "apjoms_ipasuma",
    layout: "list",
}, {
    id: "pievestie_kubikmetri",
    link: (property) => `/ipasums/${property.id}/krautejumi`,
    label: () => "Pievestie kubikmetri",
    req: "apjoms_ipasuma",
    layout: "list",
}, {
    id: "atlikums_krautuve",
    link: (property) => `/ipasums/${property.id}/atlikums_krautuve`,
    label: () => "Atlikums krautuvē",
    req: "apjoms_ipasuma",
    layout: "list",
}, {
    id: "biotopu_veidlapa",
    link: (property) => `/ipasums/${property.id}/biotopu_veidlapa`,
    label: (property) => `Biotopu veidlapa ${property && property.habitats_completed && <b>(Nodots)</b> || ""}`,
    req: "citi",
    layout: "list",
}, {
    id: "darba_drosiba",
    link: (property) => `/ipasums/${property.id}/darba_drosiba`,
    label: (property) => `Darba drošība ${property && property.work_safety_completed && <b>(Nodots)</b> || ""}`,
    req: "citi",
    layout: "list",
}, {
    id: "pievienot_foto",
    link: (property) => `/ipasums/${property.id}/pievienot_foto`,
    label: () => "Pievienot foto",
    req: "citi",
    layout: "list",
}];
