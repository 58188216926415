import axios from "axios";
import * as React from "react";

import { NavLink, withRouter } from "react-router-dom";
import { Form, Tab } from "semantic-ui-react";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Label from "semantic-ui-react/dist/commonjs/elements/Label/Label";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { Helpers } from "../../utils/helpers";
import { PropertyListItem } from "./types/property";

interface PropertiesState {
    properties: PropertyListItem[];
    loaded: boolean;
    searchKeyword: string;
    developmentFilter: string;
}

interface PropertiesProps extends LayoutProps {
    history: any[];
}

const FILTERS = [{
    label: "Visi īpašumi",
    key: "visi_ipasumi",
    filter: () => true,
}, {
    label: "Gaida pirmo apmeklējumu",
    key: "gaida_pirmo_apmeklejumu",
    filter: (item) => item.new_meistarspirmoreiziapsekojis !== 100000000,
}, {
    label: "Aktīva zāģēšana",
    key: "aktiva_izstrade",
    filter: (item) => item.new_zagesana === 100000000,
}, {
    label: "Izstrāde apstājusies",
    key: "izstrade_apstajusies",
    filter: (item) => (item.new_zagesana === 100000001 && item.new_pievesana !== 100000000) || (item.new_pievesana === 100000001 && item.new_zagesana !== 100000000),
}, {
    label: "Aktīva pievešana",
    key: "aktiva_pievesana",
    filter: (item) => item.new_pievesana === 100000000,
}, {
    label: "Krautuve nav novesta",
    key: "krautuve_nav_novesta",
    filter: (item) => item.new_pievesana === 100000002 && item.new_uzmerijumu_statuss === 100000002,
}, {
    label: "Krautuve novesta",
    key: "krautuve_novesta",
    filter: (item) => item.new_uzmerijumu_statuss === 100000000 ||  item.new_uzmerijumu_statuss === 100000001,
}, {
    label: "Ceļa zīme",
    key: "cela_zime",
    filter: (item) => item.cela_zime === 100000001,
}];

const navLinkMobile = { style: { fontSize: 22 }};

const navLinkDesktop = { style: { fontSize: 16 }};

class PropertiesBase extends React.Component<PropertiesProps, PropertiesState> {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            properties: [],
            searchKeyword: "",
            developmentFilter: "visi_ipasumi",
        };

        document.title = "Īpašumi";
    }

    componentDidMount() {
        this.fetchProperties();
    }

    changeDevelopmentFilter = (e) => {
        this.setState({ developmentFilter: e.target.value });
    }

    fetchProperties = () =>
        axios.get(`${process.env.baseUrl}/api/properties`, {
            headers: {
                Authorization: Helpers.getSessionId(),
                ActiveType: this.props.getActiveType(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                properties: response.data.data || [],
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt īpašumus!");
        })

    searchProperty = (keyword) => {
        this.setState({
            searchKeyword: keyword.target.value,
        });
    }

    getPropertiesByState = () => {
        const filterObject = FILTERS.find(filter => filter.key === this.state.developmentFilter);

        if (!filterObject) {
            Helpers.showError("Kaut kas nogāja greizi!");
            return [];
        }

        return this.state.properties.filter(filterObject.filter);
    }

    filterProperties = (properties: any[]) => this.state.searchKeyword.length > 0 ? properties.filter(this.filterCallback) : properties;

    filterCallback = property => Helpers.toLowercase(property.felling_area_name).indexOf(this.state.searchKeyword.toLowerCase()) > -1;

    getFilteredStateProperties = () => this.filterProperties(this.getPropertiesByState());

    propertyView = (properties) => {
        if (properties.length === 0) {
            return <Message
                error
                header="Netika atrasts neviens īpašums!"
                list={[
                    "Pārliecinaties, vai atlēgvārds ir ievadīts pareizi",
                    "Īpašums ir aktīvs",
                ]}
                style={{ display: "inline-block", width: "100%" }}
            />;
        } else {
            return <div style={{ width: "100%" }}>
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                    <h2  style={{ marginBottom: 15, marginTop: 20 }}>Īpašumi</h2>
                </Responsive>

                <ResponsiveElement desktopProps={{ as: Segment.Group }}>
                    <div>
                        {properties.map(property => (
                            <Segment key={property.id} style={{ padding: 0 }}>
                                <ResponsiveElement desktopProps={navLinkDesktop} mobileProps={navLinkMobile}>
                                    <NavLink to={`/ipasums/${property.id}`} className="segment-with-navlink">
                                        <div className="propery-list-item">
                                            <div className="item">
                                                {property.felling_area_name}
                                                <span className="novads-item">
                                                    (
                                                        {property.novads},
                                                        <span style={{ color: "#21b08a", fontWeight: 600 }}> Plānots: {property.planed_cubic_meter || 0}</span>;
                                                        <span style={{ color: "#2980b9", fontWeight: 600 }}> Sazāģēts: {property.total_cut || 0}</span>;
                                                        <span style={{ color: "#e74c3c", fontWeight: 600 }}> Pievests: {property.total_residue || 0}</span>
                                                    )
                                                </span>
                                            </div>
                                            {this.state.developmentFilter === "aktiva_pievesana" && <div className="item property--categories">
                                                {this.todayFisit(property.today)}
                                                {this.visitFormat(property)}
                                            </div>}
                                            {this.state.developmentFilter === "gaida_pirmo_apmeklejumu" && <div className="item property--categories">
                                                {this.waitingVisitState(property)}
                                            </div>}
                                            {this.state.developmentFilter === "visi_ipasumi" && <div className="item">
                                                {this.propertyCategories(property)}
                                            </div>}
                                            {this.state.developmentFilter === "aktiva_izstrade" && <div className="item">
                                                {this.activeDevelopmentType(property)}
                                            </div>}
                                        </div>
                                    </NavLink>
                                </ResponsiveElement>
                            </Segment>
                        ))}
                    </div>
                </ResponsiveElement>
            </div>;
        }
    }

    activeDevelopmentType = (property) => {
        if (property.new_tips === 100000001) {
            return <Label color="red" horizontal>!</Label>;
        }

        return null;
    }

    propertyCategories = (property) => {
        const filterTemp = [ ...FILTERS ];
        // Remove all properties filter
        filterTemp.shift();

        const propertyCategories = filterTemp.filter(filter => filter.filter(property));

        if (propertyCategories.length === 0) {
            return null;
        }

        return <div className=" property--categories">
            {propertyCategories.map(category => <Label
                key={category.key}
                color="brown"
                horizontal
            >
                {category.label}
            </Label>)}
        </div>;
    }

    waitingVisitState = (property) => {
        if (property.new_cirsanasapliecinajums === 100000001) {
            return <Label color="yellow" horizontal className="black--text">Iesniegts</Label>;
        }

        return <Label color="red" horizontal className="black--text">Iegūts</Label>;
    }

    todayFisit = (visited) => {
        if (visited) {
            return <Label color="green" horizontal>OK</Label>;
        }

        return <Label color="red" horizontal>X</Label>;
    }

    visitFormat = (property) => {
        if (!property.date) {
            return <span style={{ fontSize: 15 }}>Pēdējo reizi: -</span>;
        }

        return <span style={{ fontSize: 15 }}>Pēdējo reizi: {Helpers.minifiedDate(property.date)}</span>;
    }

    render() {
        const renderContent = () =>
            <Form>
                <Responsive as={Grid}>
                    <Grid.Row>
                        <Responsive mobile={16} computer={4} textAlign="left" as={Grid.Column}>
                            <Form.Field className="full--width--mobile">
                                <label>Filtri</label>
                                <select onChange={this.changeDevelopmentFilter}>
                                    {FILTERS.map(filter => (
                                        <option key={filter.key} value={filter.key}>{filter.label}</option>
                                    ))}
                                </select>
                            </Form.Field>
                        </Responsive>
                        <Responsive mobile={16} computer={4} textAlign="left" as={Grid.Column}>
                            <Form.Field className="full--width--mobile">
                                <label>Meklēt</label>
                                <Input fluid icon="search" placeholder="Meklēt īpašumu" onChange={this.searchProperty}/>
                            </Form.Field>
                        </Responsive>
                    </Grid.Row>

                    <Grid.Row style={{ width: "100%" }}>
                        {this.propertyView(this.getFilteredStateProperties())}
                    </Grid.Row>
                </Responsive>
            </Form>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props }/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 40, paddingBottom: 0 }}>
                                <Grid.Column mobile={16} computer={10}>
                                    <Header as="h1">Īpašumi</Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {!this.state.loaded ? <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader> : renderContent()}
                </div>
            </div>
        );
    }
}

export const Properties = withRouter(PropertiesBase as any);
