import axios from "axios";
import * as React from "react";
import { NavLink, Redirect } from "react-router-dom";

import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { SwitchSelect } from "../../components/SwitchSelect";
import { Helpers } from "../../utils/helpers";
import { drought, fellingTypes, taxationReason, wetReasons } from "../AddPropertyPartition";
import { PropertyPartition } from "../Properties/types/property";

interface EditPropertyPartitionState {
    propertyData: PropertyPartition;
    loaded: boolean;
    savingData: boolean;
    redirect: boolean;
}

interface PropertyProps extends LayoutProps {
    match: any;
}

export class EditPropertyPartition extends React.Component<PropertyProps, EditPropertyPartitionState> {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            propertyData: {} as any,
            savingData: false,
            redirect: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () =>
        axios.all([
            this.fetchPropertyData(),
            this.fetchPartitionData(),
        ]).then(axios.spread((property, partitions) => {
            if (property.data.success === false) {
                Helpers.showError("Neizdevās ielādēt īpašuma datus!");
            }

            if (partitions.data.success === false) {
                Helpers.showError("Neizdevās ielādēt īpašuma datus!");
            }

            this.setState({
                loaded: true,
                propertyData: {
                    ...property.data.data,
                    ...partitions.data.data,
                },
            });
        }))

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })

    fetchPartitionData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/partition/${this.props.match.params.nogabals}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })

    saveChanges = () => {
        this.setState({ savingData: true }, () => {
            axios.put(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/partition/${this.props.match.params.nogabals}`, {
                ...this.state.propertyData,
            }, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            })
            .then(response => {
                if (response.data.success) {
                    this.setState({ savingData: false, redirect: true });
                } else {
                    Helpers.showError("Neizdevās saglabāt datus");
                }
            })
            .catch(_error => {
                Helpers.showError("Neizdevās saglabāt datus");
            });
        });
    }

    onValueChange = (field, value) => {
        const tempData = { ...this.state.propertyData } as any;
        tempData[field] = value;

        this.setState({ propertyData: tempData });
    }

    render() {
        const { propertyData } = this.state;
        const propertyId = this.props.match.params.propertyId;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}`}/>;
        }

        const pageContent = <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
            <Form>
                <Grid>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Zemes vienības numurs</label>
                            <input
                                type="number"
                                pattern="[0-9]*"
                                onChange={e => this.onValueChange("zmvs_kadnu", e.target.value)}
                                value={propertyData.zmvs_kadnu}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Nogabala numurs</label>
                            <input
                                type="number"
                                pattern="[0-9]*"
                                onChange={e => this.onValueChange("nogabals", e.target.value)}
                                value={propertyData.nogabals}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Cirtes veids</label>
                            <select
                                 onChange={e => this.onValueChange("felling_type", e.target.value)}
                            >
                                {fellingTypes.map(fellingType => (
                                    <option key={fellingType.id} value={fellingType.id} { ...propertyData.felling_type === fellingType.id ? { selected: true } : {} }>{fellingType.label}</option>
                                ))}
                            </select>
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Slapjš/Sauss</label>
                            <select
                                onChange={e => this.onValueChange("drought", e.target.value)}
                            >
                                {drought.map(reason => (
                                    <option key={reason.id} value={reason.id} { ...propertyData.drought === reason.id ? { selected: true } : {} }>{reason.label}</option>
                                ))}
                            </select>
                        </Form.Field>
                    </Grid.Row>
                    {this.state.propertyData.drought === "slapjs" && <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Slapjš - Iemesls</label>
                            <select
                                onChange={e => this.onValueChange("wet_reason", e.target.value)}
                            >
                                {wetReasons.map(reason => (
                                    <option key={reason.id} value={reason.id} { ...propertyData.wet_reason === reason.id ? { selected: true } : {} }>{reason.label}</option>
                                ))}
                            </select>
                        </Form.Field>
                    </Grid.Row>}
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Taksācija pareiza?</label>
                            <SwitchSelect
                                onChange={e => this.onValueChange("is_taxation_correct", e.target.value)}
                                selected={propertyData.is_taxation_correct}
                            />
                        </Form.Field>
                    </Grid.Row>

                   <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Taksācija - Iemesls</label>
                            <select
                                onChange={e => this.onValueChange("taxation_reason", e.target.value)}
                            >
                                {taxationReason.map(reason => (
                                    <option key={reason.id} value={reason.id} { ...propertyData.taxation_reason === reason.id ? { selected: true } : {} }>{reason.label}</option>
                                ))}
                            </select>
                        </Form.Field>
                    </Grid.Row>

                    <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                        <Divider />
                    </Responsive>

                    <Grid.Row>
                        <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" } }}>
                            <Button onClick={this.saveChanges} color="green" loading={this.state.savingData}>Saglabāt</Button>
                        </ResponsiveElement>
                    </Grid.Row>
                </Grid>
            </Form>
        </ResponsiveElement>;

        const backButton = <NavLink to={`/ipasums/${propertyId}`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Labot nogabalu
                                        <Header.Subheader>{propertyData && propertyData.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Labot nogabalu
                                        <Header.Subheader>{propertyData && propertyData.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}
