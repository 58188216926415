import axios from "axios";
import Upload from "rc-upload";
import * as React from "react";
import { Button, Card, Divider, Image, Progress, Responsive } from "semantic-ui-react";
import { Helpers } from "../../utils/helpers";
import { ResponsiveElement } from "../ResponsiveElement";

interface ImageUploaderProps {
    type: string;
    propertyId: number;
    cadastreNumber: number;
}

interface ImageUploaderState {
    deletingFile: null | string;
    imageUploading: boolean;
    imageUploadProgress: number;
    images: any[];
}

export class ImageUploader extends React.Component<ImageUploaderProps, ImageUploaderState> {
    interval;

    constructor(props) {
        super(props);

        this.state = {
            deletingFile: null,
            imageUploading: false,
            imageUploadProgress: 0,
            images: [],
        };
    }

    componentDidMount() {
        this.fetchImages();

        this.interval = setInterval(() => {
            this.checkExpiredImage();
        }, 3000);
    }

    componentWillReceiveProps() {
        this.setState({ images: [] }, () => {
            this.fetchImages();
        });
    }

    checkExpiredImage = () => {
        const tempImages = [ ...this.state.images ];
        const activeImages = tempImages.filter(image => new Date(image.expire_date).getTime() > Date.now());

        if (tempImages.length !== activeImages.length) {
            this.setState({ images: activeImages });
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    fetchImages = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.propertyId}/images/${this.props.type}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({ images: response.data.data || [] }, () => {
                this.checkExpiredImage();
            });
        })
        .catch(_error => {
            Helpers.showError("Neizdevās ielādēt īpašuma attēlus!");
        })

    deleteFile = (id, fileName) => {
        this.setState({ deletingFile: fileName }, () => {
            axios.post(`${process.env.baseUrl}/api/properties/image/delete`, {
                id,
                fileName,
                cadastreNumber: this.props.cadastreNumber,
            }, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            })
            .then(response => {
                if (response.data.success) {
                    const tempData = [ ...this.state.images ];
                    const imageIndex = tempData.findIndex(image => image.id === id);

                    if (imageIndex > -1) {
                        tempData.splice(imageIndex, 1);
                    }

                    this.setState({ images: tempData, deletingFile: null });
                } else {
                    Helpers.showError("Neizdevās izdzēst attēlu");
                }
            })
            .catch(_error => {
                Helpers.showError("Neizdevās izdzēst attēlu");
            });
        });
    }

    render() {
        // tslint:disable-next-line:no-this-assignment
        const self = this;

        const defaultFilesList = (this.state.images || []).map(image => ({
            uid: image.id,
            name: image.image_url,
            status: "done",
            url: `https://s3.eu-central-1.amazonaws.com/dizozols-assets/${image.image_url}`,
            thumbUrl: `https://s3.eu-central-1.amazonaws.com/dizozols-assets/${image.image_url}`,
        }));

        const uploadProps = {
            name: "image",
            multiple: true,
            action: `${process.env.baseUrl}/api/properties/image`,
            headers: { Authorization: Helpers.getSessionId() },
            data: {
                felling_area_id: this.props.propertyId,
                cadastreNumber: this.props.cadastreNumber,
                type: this.props.type,
            },
            onProgress(step) {
                self.setState({ imageUploadProgress: step.percent });
            },
            onSuccess(response) {
                if (response.success) {
                    const tempData = [ ...self.state.images ];
                    tempData.push({
                        id: response.id,
                        expire_date: response.expireDate,
                        image_url: response.filename,
                    });

                    self.setState({ images: tempData, imageUploading: false });
                } else {
                    self.setState({ imageUploading: false });
                }
            },
            beforeUpload() {
                self.setState({
                    imageUploading: true,
                });
            },
        };

        return (
            <div>
                <Upload {...uploadProps} ref="inner">
                    <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
                        <Button
                            icon="upload"
                            label={{
                                basic: true,
                                content: "Izvēlēties failu",
                            }}
                            labelPosition="right"
                        />
                    </ResponsiveElement>

                    {this.state.imageUploading && <div style={{ marginTop: 10 }}>
                        <Progress percent={this.state.imageUploadProgress} indicating progress/>
                    </div>}
                </Upload>

                {defaultFilesList.length > 0 && <div>
                    <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                        <Divider />
                    </Responsive>

                    <div style={{ marginTop: 15 }}>
                        <ResponsiveElement desktopProps={{ itemsPerRow: 4 }} mobileProps={{ itemsPerRow: 1 }}>
                            <Card.Group>
                                {defaultFilesList.map(file => (
                                    <Card className="mobile--no-side-margin">
                                        <Image
                                            fluid
                                            src={file.thumbUrl}
                                            as="a"
                                            href={file.thumbUrl}
                                            target="_blank"
                                        />

                                        <Card.Content extra>
                                            <div className="ui two buttons">
                                                <Button
                                                    color="red"
                                                    loading={this.state.deletingFile === file.name}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.deleteFile(file.uid, file.name);
                                                    }}
                                                >
                                                    Dzēst attēlu
                                                </Button>
                                            </div>
                                        </Card.Content>
                                    </Card>
                                ))}
                            </Card.Group>
                        </ResponsiveElement>
                    </div>
                </div>}
            </div>
        );
    }
}
