import axios from "axios";
import * as React from "react";
import { NavLink, Redirect } from "react-router-dom";

import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { SwitchSelect } from "../../components/SwitchSelect";
import { Helpers } from "../../utils/helpers";
import { PropertyInformation } from "../Properties/types/property";

interface AddPropertyInformationState {
    propertyData: PropertyInformation;
    loaded: boolean;
    savingData: boolean;
    redirect: boolean;
}

interface PropertyProps extends LayoutProps {
    match: any;
}

const removalTypes = [{
    id: "tuvu_pie_cela",
    label: "Tuvu pie ceļa",
}, {
    id: "videji_talu_no_cela",
    label: "Vidēji tālu no ceļa",
}, {
    id: "talu_no_cela",
    label: "Tālu no ceļa",
}, {
    id: "tuvu_cauri_kaiminiem",
    label: "Tuvu cauri kaimiņiem",
}, {
    id: "videji_talu_cauri_kaiminiem",
    label: "Vidēji tālu cauri kaimiņiem",
}, {
    id: "talu_cauri_kaiminiem",
    label: "Tālu cauri kaimiņiem",
}, {
    id: "cauri_vairakiem_kaiminiem",
    label: "Cauri vairākiem kaimiņiem",
}];

const borderCondition = [{
    id: "laba_stavokli",
    label: "Labā stāvoklī",
}, {
    id: "videja_stavokli",
    label: "Vidējā stāvoklī",
}, {
    id: "slikta_stavokli",
    label: "Sliktā stāvoklī",
}, {
    id: "nav",
    label: "Nav",
}];

export class AddPropertyInformation extends React.Component<PropertyProps, AddPropertyInformationState> {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            propertyData: {} as any,
            savingData: false,
            redirect: false,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    saveChanges = () => {
        this.setState({ savingData: true }, () => {
            axios.put(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/information`, {
                ...this.state.propertyData,
            }, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            })
            .then(response => {
                if (response.data.success) {
                    this.setState({ savingData: false, redirect: true });
                } else {
                    Helpers.showError("Neizdevās saglabāt datus");
                }
            })
            .catch(_error => {
                Helpers.showError("Neizdevās saglabāt datus");
            });
        });
    }

    onValueChange = (field, value) => {
        const tempData = { ...this.state.propertyData } as any;
        tempData[field] = value;

        this.setState({ propertyData: tempData });
    }

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/information`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                propertyData: response.data.data || null,
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        })

    render() {
        const { propertyData } = this.state;
        const propertyId = this.props.match.params.propertyId;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}`}/>;
        }

        const pageContent =  <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
            <Form>
                <Grid>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Koksnes izvešana no īpašuma</label>
                            <select
                                onChange={e => this.onValueChange("wood_removal_from_property", e.target.value)}
                            >
                                {removalTypes.map(fellingType => (
                                    <option key={fellingType.id} value={fellingType.id} { ...propertyData.wood_removal_from_property === fellingType.id ? { selected: true } : {} }>{fellingType.label}</option>
                                ))}
                            </select>
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Robežu un stigu stāvoklis</label>
                            <select
                                onChange={e => this.onValueChange("border_state", e.target.value)}
                            >
                                {borderCondition.map(fellingType => (
                                    <option key={fellingType.id} value={fellingType.id} { ...propertyData.border_state === fellingType.id ? { selected: true } : {} }>{fellingType.label}</option>
                                ))}
                            </select>
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Iespējams biotops</label>
                            <SwitchSelect
                                onChange={e => this.onValueChange("possible_habitat", e.target.value)}
                                selected={propertyData.possible_habitat}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Ļoti liela ligzda</label>
                            <SwitchSelect
                                onChange={e => this.onValueChange("large_socket", e.target.value)}
                                selected={propertyData.large_socket}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Koka caurmērs virs 1.3m</label>
                            <SwitchSelect
                                onChange={e => this.onValueChange("wood_diameter_over_1_3", e.target.value)}
                                selected={propertyData.wood_diameter_over_1_3}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Melnalksnis + Db</label>
                            <SwitchSelect
                                onChange={e => this.onValueChange("black_alder_db", e.target.value)}
                                selected={propertyData.black_alder_db}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Purvājs + spilves</label>
                            <SwitchSelect
                                onChange={e => this.onValueChange("is_swamp", e.target.value)}
                                selected={propertyData.is_swamp}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Grava + platlapji</label>
                            <SwitchSelect
                                onChange={e => this.onValueChange("is_broadband", e.target.value)}
                                selected={propertyData.is_broadband}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Piepes</label>
                            <SwitchSelect
                                onChange={e => this.onValueChange("piepes", e.target.value)}
                                selected={propertyData.piepes}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Izgāzti lieli celmi</label>
                            <SwitchSelect
                                onChange={e => this.onValueChange("big_stumps", e.target.value)}
                                selected={propertyData.big_stumps}
                            />
                        </Form.Field>
                    </Grid.Row>

                    <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                        <Divider />
                    </Responsive>

                    <Grid.Row>
                        <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" } }}>
                            <Button onClick={this.saveChanges} color="green" loading={this.state.savingData}>Saglabāt</Button>
                        </ResponsiveElement>
                    </Grid.Row>
                </Grid>
            </Form>
        </ResponsiveElement>;

        const backButton = <NavLink to={`/ipasums/${propertyId}`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        const backButtonDesktop = <NavLink to={`/ipasums/${propertyId}`}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Informācija par īpašumu
                                        <Header.Subheader>{propertyData && propertyData.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Informācija par īpašumu
                                        <Header.Subheader>{propertyData && propertyData.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}
