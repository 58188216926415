import axios from "axios";
import * as React from "react";
import { NavLink, Redirect } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";

import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { stackingTypes } from "../../utils/constants";
import { Helpers } from "../../utils/helpers";
import { PropertyDevelopmentMin } from "../Properties/types/property";

interface CutCubicMetersState {
    redirect: boolean;
    loaded: boolean;
    data: PropertyDevelopmentMin;
    savingData: boolean;
    cutcubicmeters: any[];
    woodYard: any[];
    selectedWoodYard: string;
    woodyardError: boolean;
}

interface CutCubicMetersProps extends LayoutProps {
    match: any;
    history: any[];
}

export class CutCubicMeters extends React.Component<CutCubicMetersProps, CutCubicMetersState> {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loaded: false,
            data: {} as any,
            woodYard: [],
            savingData: false,
            cutcubicmeters: stackingTypes.map(type => ({
                type: type.key,
                value: null,
            })),
            selectedWoodYard: "",
            woodyardError: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchPropertyData = () => axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/development`, {
        headers: {
            Authorization: Helpers.getSessionId(),
        },
    })

    fetchPropertyWoodYard = () => axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/wood_yard`, {
        headers: {
            Authorization: Helpers.getSessionId(),
        },
    })

    fetchData = () =>
        axios.all([
            this.fetchPropertyData(),
            this.fetchPropertyWoodYard(),
        ]).then(axios.spread((property, woodYard) => {
            if (property.data.success === false) {
                Helpers.showError("Neizdevās ielādēt īpašuma datus!");
            }

            if (woodYard.data.success === false) {
                Helpers.showError("Neizdevās ielādēt krautuves!");
            }

            this.setState({
                loaded: true,
                data: property.data.data || null,
                woodYard: woodYard.data.data || [],
            });
        }))

    saveChanges = () => {
        this.setState({ savingData: true }, () => {

            if (this.state.selectedWoodYard.length === 0) {
                return this.setState({ woodyardError: true, savingData: false }, () => {
                    // Scroll to top of page if having error
                    window.scrollTo(0, 0);
                });
            }

            axios.put(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/cutcubicmeters`, {
                cutcubicmeters: this.state.cutcubicmeters,
                woodyard: this.state.selectedWoodYard,
            }, {
                headers: {
                    Authorization: Helpers.getSessionId(),
                },
            })
            .then(response => {
                if (response.data.success) {
                    this.setState({ savingData: false, redirect: true });
                } else {
                    this.setState({ savingData: false });
                    Helpers.showError("Neizdevās saglabāt datus");
                }
            })
            .catch(_error => {
                this.setState({ savingData: false });
                Helpers.showError("Neizdevās saglabāt datus");
            });
        });
    }

    getTypeValue = (type) => {
        const typeData = this.state.cutcubicmeters.find(item => item.type === type);
        return typeData ? typeData.value : null;
    }

    onValueChange = (type, value) => {
        const tempData = [ ...this.state.cutcubicmeters ];
        const typeIndex = tempData.findIndex(item => item.type === type);

        if (typeIndex > -1) {
            tempData[typeIndex].value = value;
        } else {
            tempData.push({ type, value });
        }

        this.setState({ cutcubicmeters: tempData });
    }

    formatWoodYardSelect = (item) => {
        if (item.new_komentars) {
            return `${item.new_kods || ""}:${item.new_komentars || ""}`;
        }

        return item.new_kods;
    }

    changeWoodYard = (_e, data) => {
        if (this.state.selectedWoodYard !== data.value) {
            this.setState(prevState => ({
                woodyardError: false,
                selectedWoodYard: data.value,
                cutcubicmeters: prevState.cutcubicmeters.map(item => ({
                    type: item.type,
                    value: null,
                })),
            }));
        }
    }

    render() {
        const propertyId = this.props.match.params.propertyId;
        const data = this.state.data;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}`}/>;
        }

        const woodYardItems = this.state.woodYard.map(item => ({
            key: item.new_krautuveid,
            value: item.new_kods,
            text: this.formatWoodYardSelect(item),
        }));

        const loadedPageContent =
            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
                <Form onSubmit={this.saveChanges}>
                    <Grid>
                        <Grid.Row>
                            <Form.Field className="full--width" inline>
                                <label>Krautuve</label>
                                <Dropdown
                                    selection
                                    name="false"
                                    options={woodYardItems}
                                    selectOnNavigation={false}
                                    onChange={this.changeWoodYard}
                                    placeholder="Izvēlieties krautuvi"
                                    error={this.state.woodyardError}
                                />
                            </Form.Field>

                            {this.state.woodyardError && <span style={{
                                color: "#9F3A38",
                                marginLeft: 162,
                                fontWeight: 600,
                            }}>Lūdzu izvēlēties krautuvi!</span>}
                        </Grid.Row>

                        {stackingTypes.map((stacking) => (
                            <Grid.Row>
                                <Form.Field className="full--width" inline>
                                    <label>{stacking.title}</label>
                                    <input
                                        value={this.getTypeValue(stacking.key) || ""}
                                        onChange={e => this.onValueChange(stacking.key, e.target.value)}
                                        type="number"
                                        pattern="[0-9]*"
                                    />
                                </Form.Field>
                            </Grid.Row>
                        ))}

                        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                            <Divider />
                        </Responsive>

                        <Grid.Row>
                            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" } }}>
                                <Button color="green" loading={this.state.savingData}>Saglabāt</Button>
                            </ResponsiveElement>
                        </Grid.Row>
                    </Grid>
                </Form>
            </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        const backButton = <NavLink to={`/ipasums/${propertyId}/izstrade`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Nozāģētie kubikmetri
                                        <Header.Subheader>{data && data.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Nozāģētie kubikmetri
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> {data && data.felling_area_name}
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}
