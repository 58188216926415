import axios from "axios";
import * as React from "react";
import cookie from "react-cookies";
import { Logo } from "../../components/Logo";

import { createForm } from "rc-form";

import { Button, Form, Grid, Message, Responsive } from "semantic-ui-react";

interface AuthProps {
    isAuthorized(data: any): void;
    form: any;
}

interface AuthState {
    waitingForResponse: boolean;
    formSubmitted: boolean;
    haveError: boolean;
    errorMessage: string;
}

class AuthorizationClean extends React.Component<AuthProps, AuthState> {

    constructor(props) {
        super(props);

        this.state = {
            waitingForResponse: false,
            formSubmitted: false,

            haveError: false,
            errorMessage: "",
        };
    }

    componentDidMount() {
        document.title = "Dižozols cenu lapa | Autorizēties";
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            haveError: false,
            errorMessage: "",
        });

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    formSubmitted: true,
                    waitingForResponse: true,
                }, () => {
                    // Let's try to login
                    axios.post(`${process.env.baseUrl}/login`, {
                        username: values.username,
                        password: values.password,
                    })
                    .then((result) => {
                        // If requested data was with success tag
                        if (result.data.success === true) {
                            // Change state
                            this.setState({ waitingForResponse: false }, () => {
                                const expireDate = new Date();
                                expireDate.setHours(expireDate.getHours() + 8);
                                // Set authorization cookies
                                cookie.save("ozols-forest-token", result.data.sessionId, {
                                    expires: expireDate,
                                });

                                setTimeout(() => {
                                    this.props.isAuthorized(result.data.data);
                                }, 500);
                            });
                       } else {
                            this.props.form.setFields({
                                password: {
                                    value: "",
                                },
                            });

                            this.setState({
                                waitingForResponse: false,
                                haveError: true,
                                errorMessage: result.data.error,
                            });
                       }
                   })
                   .catch(() => {
                        this.props.form.setFields({
                            password: {
                                value: "",
                            },
                        });

                        this.setState({
                            waitingForResponse: false,
                            haveError: true,
                            errorMessage: "Kaut kas nogāja greizi!",
                        });
                   });
                });
            }
        });
    }

    render() {
        const { getFieldProps, getFieldError } = this.props.form;

        const usernameInput = {
            ...getFieldProps("username", {
                rules: [{
                    required: true,
                    message: "Lūdzu ievadiet paroli!",
                }],
            }),
            error: getFieldError("username"),
        };

        const passwordInput = {
            ...getFieldProps("password", {
                rules: [{
                    required: true,
                    message: "Lūdzu ievadiet paroli!",
                }],
            }),
            error: getFieldError("password"),
        };

        return (
            <div className="login-form">
                <style>
                    {`
                        .loader {
                            background: url("${process.env.baseUrl}/assets/images/background.jpg") no-repeat center center fixed;
                            -webkit-background-size: cover;
                            -moz-background-size: cover;
                            -o-background-size: cover;
                            background-size: cover;
                        }

                        body > #root,
                        body > #root > div,
                        body > #root > div > div.login-form {
                            height: 100%;
                        }
                    `}
                </style>

                <Grid textAlign="center" verticalAlign="middle" style={{ height: "100%" }} className="without-margin">
                    <Grid.Column className="login-form-grid">
                        <div className="login--logo">
                            <Logo/>
                        </div>

                        {this.state.haveError && <Message negative>
                            <p>{this.state.errorMessage}</p>
                        </Message>}

                        <Form size="large">
                            <Form.Field>
                                <label style={{ textAlign: "left" }}>Lietotājvārds</label>

                                <Responsive as={Form.Input} fluid size="huge" maxWidth={Responsive.onlyMobile.maxWidth} { ...usernameInput }/>
                                <Responsive as={Form.Input} fluid size="small" minWidth={Responsive.onlyTablet.minWidth} { ...usernameInput }/>
                            </Form.Field>

                            <Form.Field>
                                <label style={{ textAlign: "left" }}>Parole</label>

                                <Responsive as={Form.Input} fluid type="password" size="huge" maxWidth={Responsive.onlyMobile.maxWidth} { ...passwordInput }/>
                                <Responsive as={Form.Input} fluid type="password" size="small" minWidth={Responsive.onlyTablet.minWidth} { ...passwordInput }/>
                            </Form.Field>

                            <div style={{ marginTop: 35 }}>
                                <Responsive as={Button} fluid size="massive" maxWidth={Responsive.onlyMobile.maxWidth} onClick={this.handleSubmit} loading={this.state.waitingForResponse}>
                                    Ienākt
                                </Responsive>
                                <Responsive as={Button} fluid size="large" minWidth={Responsive.onlyTablet.minWidth} onClick={this.handleSubmit} loading={this.state.waitingForResponse}>
                                    Ienākt
                                </Responsive>
                            </div>
                        </Form>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export const Authorization = createForm()(AuthorizationClean);
