import axios from "axios";
import * as React from "react";
import { NavLink, Redirect } from "react-router-dom";

import Confirm from "semantic-ui-react/dist/commonjs/addons/Confirm";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Transition from "semantic-ui-react/dist/commonjs/modules/Transition";
import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { Helpers } from "../../utils/helpers";
import { fellingTypes, wetReasons } from "../AddPropertyPartition";
import { PropertyMinimal } from "../Properties/types/property";
import { getPropertyActions } from "./actions";

interface PropertyState {
    loaded: boolean;
    partitions: Partitions[];
    propertyData: PropertyMinimal | null;
    redirectToHomepage: boolean;
    finishConfirmVisible: boolean;
    areaDataLoaded: boolean;
}

interface Partitions {
    id: number;
    nogabals: number;
    kvartals: number;
}

interface PropertyProps extends LayoutProps {
    match: any;
}

export class Property extends React.Component<PropertyProps, PropertyState> {
    constructor(props) {
        super(props);

        this.state = {
            finishConfirmVisible: false,
            redirectToHomepage: false,
            loaded: false,
            propertyData: null,
            partitions: [],
            areaDataLoaded: false,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();

        // Load property data if user have permissions
        if (this.props.getActiveType() === 100000000) {
            this.fetchPropertyAreas();
        }
    }

    fetchPropertyAreas = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/areas`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                areaDataLoaded: true,
                partitions: response.data.data || [],
            });
        })
        .catch(_error => {
            this.setState({ loaded: true, areaDataLoaded: true });
            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        })

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: this.props.getActiveType() !== 100000000 ? true : false,
                propertyData: response.data.data || null,
            });
        })
        .catch(_error => {
            if (this.props.getActiveType() !== 100000000) {
                this.setState({ loaded: true });
            }

            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        })

    finishJobWithProperty = () =>
        axios.put(`${process.env.baseUrl}/api/properties/completed`, {
            propertyId: this.props.match.params.propertyId,
        }, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            if (response.data.success) {
                this.setState({ redirectToHomepage: true });
            } else {
                Helpers.showError("Neizdevās pabeigt darbu ar īpašumu! Lūdzu sazinieties ar ofisu");
            }
        })
        .catch(_error => {
            Helpers.showError("Neizdevās pabeigt darbu ar īpašumu! Lūdzu sazinieties ar ofisu");
        })

    openConfirm = () => this.setState({ finishConfirmVisible: true });
    closeConfirm = () => this.setState({ finishConfirmVisible: false });

    deletePartition = (partition) => {
        axios.delete(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/partition/${partition}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            if (response.data.success) {
                const tempData = [ ...this.state.partitions ];
                const partitionIndex = tempData.findIndex(item => item.nogabals === partition);

                if (partitionIndex > -1) {
                    tempData.splice(partitionIndex, 1);

                    this.setState({
                        partitions: tempData,
                    });
                }
            } else {
                Helpers.showError("Kaut kas nogāja greizi!");
            }
        })
        .catch(_error => {
            Helpers.showError("Kaut kas nogāja greizi!");
        });
    }

    finishPropertyExpert = () => {
        axios.put(`${process.env.baseUrl}/api/properties/expert/completed`, {
            propertyId: this.props.match.params.propertyId,
        }, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            if (response.data.success) {
                this.setState({ redirectToHomepage: true });
            } else {
                Helpers.showError("Neizdevās pabeigt darbu ar īpašumu! Lūdzu sazinieties ar ofisu");
            }
        })
        .catch(_error => {
            Helpers.showError("Neizdevās pabeigt darbu ar īpašumu! Lūdzu sazinieties ar ofisu");
        });
    }

    render() {
        if (this.state.redirectToHomepage) {
            return <Redirect to="/"/>;
        }

        const { propertyData } = this.state;
        const propertyId = this.props.match.params.propertyId;
        const navLinkMobile = { style: { fontSize: 22 }};
        const navLinkDesktop = { style: { fontSize: 16 }};

        const checkPartitionsIsEmpty = (data) => {
            if (!data || data.length === 0) {
                return <Grid.Row style={{ width: "100%" }}>
                    <Message warning>
                        <Message.Header>Netika atrasts neviens nogabals</Message.Header>
                    </Message>
                </Grid.Row>;
            }

            return data.map(partition => (
                <ResponsiveElement>
                    <Transition.Group duration={100}>
                        <Segment style={{ padding: 0, marginBottom: 10 }}>
                            <ResponsiveElement desktopProps={navLinkDesktop} mobileProps={navLinkMobile}>
                                <div>
                                    <NavLink to={`/ipasums/${propertyId}/nogabals/${partition.nogabals}`} className="segment-with-navlink">
                                        <Grid>
                                            <Grid.Row>
                                                <Header size="medium">{partition.nogabals}. nogabals</Header>
                                            </Grid.Row>

                                            <Grid.Row style={{ fontSize: 16, color: "#a2a2a2" }} className="narrow-grid-row">
                                                <div style={{ width: "100%" }}>{Helpers.getLabelById(fellingTypes, partition.felling_type)}</div>
                                                <div style={{ width: "100%" }}>{Helpers.getLabelById(wetReasons, partition.wet_reason)}</div>
                                            </Grid.Row>

                                            <Grid.Row style={{ fontSize: 16, color: "#a2a2a2" }} className="narrow-grid-row">
                                                Taksācija - {partition.is_taxation_correct ? "Jā" : "Nē"}
                                            </Grid.Row>
                                        </Grid>
                                    </NavLink>

                                    <div style={{ position: "absolute", top: 25, right: 10, zIndex: 9999 }}>
                                        <Button negative size="tiny" onClick={() => this.deletePartition(partition.nogabals)}>Dzēst</Button>
                                    </div>
                                </div>
                            </ResponsiveElement>
                        </Segment>
                    </Transition.Group>
                </ResponsiveElement>
            ));
        };

        const forestExpertMenu = () =>
            <Grid>
                <Grid.Row>
                    <Header size="medium">Nogabali</Header>
                </Grid.Row>
                <Grid.Row>
                    {this.state.areaDataLoaded ?
                        checkPartitionsIsEmpty(this.state.partitions) :
                        <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>
                    }
                </Grid.Row>

                <Grid.Row>
                    <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                        <Divider />
                    </Responsive>

                    <ResponsiveElement desktopProps={{ as: Segment.Group }}>
                        <Transition.Group duration={100}>
                            <Segment style={{ padding: 0 }}>
                                <ResponsiveElement desktopProps={navLinkDesktop} mobileProps={navLinkMobile}>
                                    <NavLink to={`/ipasums/${propertyId}/pievienot_nogabalu`} className="segment-with-navlink">Pievienot nogabalu</NavLink>
                                </ResponsiveElement>
                            </Segment>
                            <Segment style={{ padding: 0 }}>
                                <ResponsiveElement desktopProps={navLinkDesktop} mobileProps={navLinkMobile}>
                                    <NavLink to={`/ipasums/${propertyId}/informacija`} className="segment-with-navlink">Pievienot informāciju par īpašumu</NavLink>
                                </ResponsiveElement>
                            </Segment>
                            <Segment style={{ padding: 0 }} inverted color="orange" textAlign="center">
                                <ResponsiveElement desktopProps={navLinkDesktop} mobileProps={navLinkMobile}>
                                    <a className="segment-with-navlink white" style={{ color: "#fff", cursor: "pointer" }} onClick={(e) => {
                                        e.preventDefault();
                                        this.finishPropertyExpert();
                                    }}><Icon name="check"/> Pabeigt darbu ar īpašumu</a>
                                </ResponsiveElement>
                            </Segment>
                        </Transition.Group>
                    </ResponsiveElement>
                </Grid.Row>
            </Grid>;

        const renderMenu = () => {
            if (this.props.getActiveType() === 100000000) {
                return forestExpertMenu();
            } else if (this.props.getActiveType() === 100000001) {
                return <div>
                    {getPropertyActions(this.props.match.params.action || null).map(action => (
                        <div className={`list-box ${action.layout}`}>
                            <div className="list-box-inner">
                                <NavLink to={action.link(this.state.propertyData)} className="segment-with-navlink">
                                    {action.label(this.state.propertyData)}
                                </NavLink>
                            </div>
                        </div>
                    ))}
                </div>;
            }

            return "";
        };

        const getBackLink = (button = true) => {
            if (!this.props.match.params.action) {
                return <NavLink to={"/"} className={button ? "ui icon left labeled button huge white-text" : ""}>
                    <Icon name="angle left"/> Visi īpašumi
                </NavLink>;
            }

            return <NavLink to={`/ipasums/${this.props.match.params.propertyId}`} className={button ? "ui icon left labeled button huge white-text" : ""}>
                <Icon name="angle left"/> {this.state.propertyData ? this.state.propertyData.felling_area_name : "Īpašums"}
            </NavLink>;
        };

        return (
            <div>
                <Confirm
                    open={this.state.finishConfirmVisible}
                    onCancel={this.closeConfirm}
                    onConfirm={this.finishJobWithProperty}
                    cancelButton="Atcelt"
                    confirmButton="Nodot"
                    content="Vai Jūst tiešam vēlaties nodot īpašumu?"
                    size="mini"
                    className="confirm--modal"
                />

                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={getBackLink()}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        {propertyData && propertyData.felling_area_name}
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        {propertyData && propertyData.felling_area_name}
                                        <Header.Subheader>
                                            {getBackLink(false)}
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} as={Grid.Column}>
                                    <div className="property-metadata">
                                        <span><label>Novads:</label> {propertyData && propertyData.novads}</span>
                                        <span><NavLink to={`/ipasums/${this.props.match.params.propertyId}/info`}>Vairāk par īpašumu</NavLink></span>
                                    </div>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} as={Grid.Column}>
                                    <div className="property-metadata">
                                        <span><label>Novads:</label> {propertyData && propertyData.novads}</span>
                                        <span><NavLink to={`/ipasums/${this.props.match.params.propertyId}/info`}>Vairāk par īpašumu</NavLink></span>
                                    </div>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {this.state.loaded && renderMenu()}
                </div>
            </div>
        );
    }
}
