import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";

import { NavLink, Redirect } from "react-router-dom";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Message from "semantic-ui-react/dist/commonjs/collections/Message/Message";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { SelectField } from "../../components/SelectD";
import { Helpers } from "../../utils/helpers";

interface HabitatsFormState {
    redirect: boolean;
    loaded: boolean;
    propertyData: any;
    habitatsData: any;
    savingData: boolean;
    errors: ErrorObject;
}

interface ErrorObject {
    haveError: boolean;
    fields: string[];
}

interface HabitatsFormProps extends LayoutProps {
    match: any;
    form;
}

export const fieldLabel = [{
    fieldId: "audzes_vecuma_koki",
    label: "Audzi veido dažāda vecuma koki",
}, {
    fieldId: "lielu_dimensiju_veci_koki",
    label: "Ir ļoti lielu dimensiju veci koki (Ø >50cm)",
}, {
    fieldId: "leni_augosi_veci_koki",
    label: "Ir lēni augoši veci koki",
}, {
    fieldId: "laukumi_grupveida_paaugu",
    label: "Audzē ir nelieli laukumi ar grupveida paaugu",
}, {
    fieldId: "nokaltusi_koki",
    label: "Nokaltuši, stāvoši koki vai stumbeņi (Ø >25cm), purvainos (Ø >20cm)",
}, {
    fieldId: "izgazti_lieli_celmi",
    label: "Izgāzti lieli celmi",
}, {
    fieldId: "putnu_sakalti",
    label: "Putnu sakalti augoši un nokaltuši koki",
}, {
    fieldId: "koki_ar_piepem",
    label: "Augoši koki ar piepēm",
}, {
    fieldId: "stumbriem_zalas_sunas",
    label: "Uz koku stumbriem aug zaļas sūnas augstāk par 1m no pamatnes",
}, {
    fieldId: "avotu_izpludes_vietas",
    label: "Avotu izplūdes vietas, avoksnāji, avotainas ieplakas",
}, {
    fieldId: "parplustosi_laukumi",
    label: "Īslaicīgi vai pastāvīgi pārplūstoši laukumi",
}, {
    fieldId: "ugunsgreka_pazimes",
    label: "Ir pazīmes par audzē notikušu ugunsgrēku pēdējo 20 gadu laikā",
}, {
    fieldId: "loti_liela_ligzda",
    label: "Ļoti liela ligzda (Ø 50 cm vai lielāks), aizsargājama suga",
}, {
    fieldId: "upes_skersojums",
    label: "Nogabals robežojas vai to šķērso strauts vai maza upīte",
}, {
    fieldId: "daudz_sfagnas",
    label: "Zemsedzē daudz sfagnu (vairāk par 50%)",
}, {
    fieldId: "ezera_robezojums",
    label: "Nogabals robežojas ar upi vai ezeru",
}, {
    fieldId: "piejuras_zemiene",
    label: "Nogabals atrodas piejūras zemienē, ir kāpas",
}, {
    fieldId: "saknu_kakls",
    label: "Koka caurmērs 1.3 m augstumā virs sakņu kakla virs 80 cm",
}, {
    fieldId: "melnalksnis",
    label: "Melnalksnis + Db, Lk",
}, {
    fieldId: "purvajs",
    label: "Purvājs (vai Nd) + spilves + nav susināts",
}, {
    fieldId: "grava",
    label: "Grava + platlapjis",
}, {
    fieldId: "lapu_koku_aleja",
    label: "Cieto lapu koku aleja",
}, {
    fieldId: "dizkoks",
    label: "Dižkoks",
}];

const getFieldLabel = (id) => {
    const labelObject = fieldLabel.find(item => item.fieldId === id);
    return labelObject ? labelObject.label : id;
};

class HabitatsFormWrapped extends React.Component<HabitatsFormProps, HabitatsFormState> {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loaded: false,
            propertyData: {},
            habitatsData: {},
            savingData: false,
            errors: {
                haveError: false,
                fields: [],
            },
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () =>
        axios.all([ this.fetchPropertyData() ]).then(axios.spread((property) => {
            if (property.data.success === false) {
                Helpers.showError("Neizdevās ielādēt īpašuma datus!");
            }

            this.setState({
                loaded: true,
                propertyData: property.data.data,
            });
        }))

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })

    saveChanges = () => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                Helpers.showError("Neizdevās saglabāt datus");
            }

            // Check if all fields are populated
            const emptyFields = Object.keys(values).reduce((list, key) => {
                if (values[key].trim() === "") {
                    list.push(key);
                }
                return list;
            }, [] as any[]);

            if (emptyFields.length > 0) {
                return this.setState({
                    errors: {
                        haveError: true,
                        fields: emptyFields,
                    },
                }, () => {
                    window.scrollTo(0, 0);
                });
            }

            this.setState({ savingData: true }, () => {
                axios.post(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/habitats_form`, {
                    ...values,
                }, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                .then(response => {
                    if (response.data.success) {
                        this.setState({ savingData: false, redirect: true });
                    } else {
                        this.setState({ savingData: false });
                        Helpers.showError("Neizdevās saglabāt datus");
                    }
                })
                .catch(_error => {
                    this.setState({ savingData: false });
                    Helpers.showError("Neizdevās saglabāt datus");
                });
            });
        });
    }

    // tslint:disable-next-line:cyclomatic-complexity
    render() {
        const { getFieldProps } = this.props.form;
        const propertyId = this.props.match.params.propertyId;
        const data = this.state.habitatsData;
        const propertyData = this.state.propertyData;

        const OPTIONS = [{
            id: "",
            label: "",
        }, {
            id: "Nav",
            label: "Nav",
        }, {
            id: "Daži",
            label: "Daži",
        }, {
            id: "Daudz",
            label: "Daudz",
        }];

        const RADIO_OPTIONS = [{
            id: "",
            label: "",
        }, {
            id: "Jā",
            label: "Jā",
        }, {
            id: "Nē",
            label: "Nē",
        }];

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}`}/>;
        }

        const loadedPageContent = <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
            <Form>
                <Grid>

                    {this.state.errors.haveError && <Grid.Row>
                        <Message
                            error
                            header="Šiem laukiem ir jābūt aizpildītiem:"
                            list={this.state.errors.fields.map(getFieldLabel)}
                            style={{ display: "block", width: "100%" }}
                        />
                    </Grid.Row>}

                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Kvartāls</label>
                            <input
                                {...getFieldProps("kvartals", {
                                    initialValue: data.kvartals || "",
                                })}
                                type="number"
                                pattern="[0-9]*"
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Nogabals</label>
                            <input
                                {...getFieldProps("nogabals", {
                                    initialValue: data.nogabals || "",
                                })}
                                type="number"
                                pattern="[0-9]*"
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Audzi veido dažāda vecuma koki</label>
                            <SelectField
                                options={OPTIONS}
                                {...getFieldProps("audzes_vecuma_koki", {
                                    initialValue: data.audzes_vecuma_koki || OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Ir ļoti lielu dimensiju veci koki (Ø >50cm)</label>
                            <SelectField
                                options={OPTIONS}
                                {...getFieldProps("lielu_dimensiju_veci_koki", {
                                    initialValue: data.lielu_dimensiju_veci_koki || OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Ir lēni augoši veci koki</label>
                            <SelectField
                                options={OPTIONS}
                                {...getFieldProps("leni_augosi_veci_koki", {
                                    initialValue: data.leni_augosi_veci_koki || OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Audzē ir nelieli laukumi ar grupveida paaugu</label>
                            <SelectField
                                options={OPTIONS}
                                {...getFieldProps("laukumi_grupveida_paaugu", {
                                    initialValue: data.laukumi_grupveida_paaugu || OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Nokaltuši, stāvoši koki vai stumbeņi (Ø >25cm), purvainos (Ø >20cm)</label>
                            <SelectField
                                options={OPTIONS}
                                {...getFieldProps("nokaltusi_koki", {
                                    initialValue: data.nokaltusi_koki || OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Izgāzti lieli celmi</label>
                            <SelectField
                                options={OPTIONS}
                                {...getFieldProps("izgazti_lieli_celmi", {
                                    initialValue: data.izgazti_lieli_celmi || OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Putnu sakalti augoši un nokaltuši koki</label>
                            <SelectField
                                options={OPTIONS}
                                {...getFieldProps("putnu_sakalti", {
                                    initialValue: data.putnu_sakalti || OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Augoši koki ar piepēm</label>
                            <SelectField
                                options={OPTIONS}
                                {...getFieldProps("koki_ar_piepem", {
                                    initialValue: data.koki_ar_piepem || OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Uz koku stumbriem aug zaļas sūnas augstāk par 1m no pamatnes</label>
                            <SelectField
                                options={OPTIONS}
                                {...getFieldProps("stumbriem_zalas_sunas", {
                                    initialValue: data.stumbriem_zalas_sunas || OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Avotu izplūdes vietas, avoksnāji, avotainas ieplakas</label>
                            <SelectField
                                options={OPTIONS}
                                {...getFieldProps("avotu_izpludes_vietas", {
                                    initialValue: data.avotu_izpludes_vietas || OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Īslaicīgi vai pastāvīgi pārplūstoši laukumi</label>
                            <SelectField
                                options={OPTIONS}
                                {...getFieldProps("parplustosi_laukumi", {
                                    initialValue: data.parplustosi_laukumi || OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Ir pazīmes par audzē notikušu ugunsgrēku pēdējo 20 gadu laikā</label>
                            <SelectField
                                options={OPTIONS}
                                {...getFieldProps("ugunsgreka_pazimes", {
                                    initialValue: data.ugunsgreka_pazimes || OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Ļoti liela ligzda (Ø 50 cm vai lielāks), aizsargājama suga</label>
                            <SelectField
                                options={RADIO_OPTIONS}
                                {...getFieldProps("loti_liela_ligzda", {
                                    initialValue: data.loti_liela_ligzda || RADIO_OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Nogabals robežojas vai to šķērso strauts vai maza upīte</label>
                            <SelectField
                                options={RADIO_OPTIONS}
                                {...getFieldProps("upes_skersojums", {
                                    initialValue: data.upes_skersojums || RADIO_OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Zemsedzē daudz sfagnu (vairāk par 50%)</label>
                            <SelectField
                                options={RADIO_OPTIONS}
                                {...getFieldProps("daudz_sfagnas", {
                                    initialValue: data.daudz_sfagnas || RADIO_OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Nogabals robežojas ar upi vai ezeru</label>
                            <SelectField
                                options={RADIO_OPTIONS}
                                {...getFieldProps("ezera_robezojums", {
                                    initialValue: data.ezera_robezojums || RADIO_OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Nogabals atrodas piejūras zemienē, ir kāpas</label>
                            <SelectField
                                options={RADIO_OPTIONS}
                                {...getFieldProps("piejuras_zemiene", {
                                    initialValue: data.piejuras_zemiene || RADIO_OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Koka caurmērs 1.3 m augstumā virs sakņu kakla virs 80 cm</label>
                            <SelectField
                                options={RADIO_OPTIONS}
                                {...getFieldProps("saknu_kakls", {
                                    initialValue: data.saknu_kakls || RADIO_OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Melnalksnis + Db, Lk</label>
                            <SelectField
                                options={RADIO_OPTIONS}
                                {...getFieldProps("melnalksnis", {
                                    initialValue: data.melnalksnis || RADIO_OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Purvājs (vai Nd) + spilves + nav susināts</label>
                            <SelectField
                                options={RADIO_OPTIONS}
                                {...getFieldProps("purvajs", {
                                    initialValue: data.purvajs || RADIO_OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Grava + platlapji</label>
                            <SelectField
                                options={RADIO_OPTIONS}
                                {...getFieldProps("grava", {
                                    initialValue: data.grava || RADIO_OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Cieto lapu koku aleja</label>
                            <SelectField
                                options={RADIO_OPTIONS}
                                {...getFieldProps("lapu_koku_aleja", {
                                    initialValue: data.lapu_koku_aleja || RADIO_OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>
                    <Grid.Row>
                        <Form.Field className="full--width">
                            <label>Dižkoks</label>
                            <SelectField
                                options={RADIO_OPTIONS}
                                {...getFieldProps("dizkoks", {
                                    initialValue: data.dizkoks || RADIO_OPTIONS[0].id,
                                })}
                            />
                        </Form.Field>
                    </Grid.Row>

                    <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                        <Divider />
                    </Responsive>

                    <Grid.Row >
                        <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" } }}>
                            <Button onClick={this.saveChanges} color="green" loading={this.state.savingData}>Saglabāt biotopu veidlapu</Button>
                        </ResponsiveElement>
                    </Grid.Row>
                </Grid >
            </Form>
        </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        const backButton = <NavLink to={`/ipasums/${propertyId}`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Biotopu veidlapa
                                        <Header.Subheader>{propertyData && propertyData.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} as={Grid.Column}>
                                    <div className="property-metadata">
                                        <span>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}/biotopu_veidlapa/visas`}>
                                                Visas biotopu veidlapas
                                            </NavLink>
                                        </span>
                                    </div>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Biotopu veidlapa
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> {propertyData && propertyData.felling_area_name}
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} as={Grid.Column}>
                                    <div className="property-metadata">
                                        <span>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}/biotopu_veidlapa/visas`}>
                                                Visas biotopu veidlapas
                                            </NavLink>
                                        </span>
                                    </div>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}

export const HabitatsForm = createForm()(HabitatsFormWrapped as any);
