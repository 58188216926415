import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";

import { NavLink, Redirect } from "react-router-dom";
import { Button, Modal, Table } from "semantic-ui-react";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import { HeaderMenu } from "../../components/HeaderMenu";
import { LayoutProps } from "../../components/LayoutWrapper";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { Helpers } from "../../utils/helpers";
import { fieldLabel } from "../HabitatsForm";

interface HabitatsFormState {
    redirect: boolean;
    loaded: boolean;
    propertyData: any;
    habitatsData: any[];
    savingData: boolean;
    modalOpen: boolean;
    errors: ErrorObject;
    activePage: number | null;
}

interface ErrorObject {
    haveError: boolean;
    fields: string[];
}

interface HabitatsFormProps extends LayoutProps {
    match: any;
    form;
}

class HabitatsFormWrapped extends React.Component<HabitatsFormProps, HabitatsFormState> {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            redirect: false,
            loaded: false,
            propertyData: {},
            habitatsData: [],
            savingData: false,
            activePage: null,
            errors: {
                haveError: false,
                fields: [],
            },
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchPropertyHabitats = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/habitats_form`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })

    fetchData = () =>
        axios.all([
            this.fetchPropertyData(),
            this.fetchPropertyHabitats(),
        ]).then(axios.spread((property, habitats) => {
            if (property.data.success === false || habitats.data.success === false) {
                Helpers.showError("Neizdevās ielādēt īpašuma datus!");
            }

            this.setState({
                loaded: true,
                propertyData: property.data.data,
                habitatsData: habitats.data.data,
            });
        }))

    fetchPropertyData = () =>
        axios.get(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })

    saveChanges = () => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                Helpers.showError("Neizdevās saglabāt datus");
            }

            // Check if all fields are populated
            const emptyFields = Object.keys(values).reduce((list, key) => {
                if (values[key].trim() === "") {
                    list.push(key);
                }
                return list;
            }, [] as any[]);

            if (emptyFields.length > 0) {
                return this.setState({
                    errors: {
                        haveError: true,
                        fields: emptyFields,
                    },
                }, () => {
                    window.scrollTo(0, 0);
                });
            }

            this.setState({ savingData: true }, () => {
                axios.post(`${process.env.baseUrl}/api/properties/${this.props.match.params.propertyId}/habitats_form`, {
                    ...values,
                }, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                .then(response => {
                    if (response.data.success) {
                        this.setState({ savingData: false, redirect: true });
                    } else {
                        this.setState({ savingData: false });
                        Helpers.showError("Neizdevās saglabāt datus");
                    }
                })
                .catch(_error => {
                    this.setState({ savingData: false });
                    Helpers.showError("Neizdevās saglabāt datus");
                });
            });
        });
    }

    handleOpen = (id) => this.setState({ modalOpen: true, activePage: id });

    handleClose = () => this.setState({ modalOpen: false, activePage: null });

    // tslint:disable-next-line:cyclomatic-complexity
    render() {
        const propertyId = this.props.match.params.propertyId;
        const propertyData = this.state.propertyData;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}`}/>;
        }

        const labelValue = (key) => {
            const labelObject = fieldLabel.find(label => label.fieldId === key);
            return labelObject ? labelObject.label : "";
        };
        const filteredKeys = (object) => Object.keys(object).filter(labelValue).map(key => ({ [key]: object[key] }));

        const modalData = () => {
            const data = this.state.habitatsData.find(item => item.id === this.state.activePage);
            return data ? data : [];
        };

        const loadedPageContent = <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
            <Table basic="very" unstackable style={{ background: "#fff", padding: "10px 15px" }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Kvartāls</Table.HeaderCell>
                        <Table.HeaderCell>Nogabals</Table.HeaderCell>
                        <Table.HeaderCell style={{ width: 120 }}></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {this.state.habitatsData.map(data => (
                        <Table.Row>
                            <Table.Cell>
                                {data.kvartals}
                            </Table.Cell>
                            <Table.Cell>
                                {data.nogabals}
                            </Table.Cell>
                            <Table.Cell>
                                <Button size="mini" onClick={() => this.handleOpen(data.id)}>Apskatīt datus</Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}

                    <Modal open={this.state.modalOpen} centered={false}>
                        <Modal.Content>
                            <Table basic="very" unstackable>
                                <Table.Body>
                                    {filteredKeys(modalData()).map(dataValue => (
                                        <Table.Row>
                                            <Table.Cell>
                                                {labelValue(Object.keys(dataValue)[0])}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {dataValue[Object.keys(dataValue)[0]]}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button positive={true} onClick={this.handleClose}>
                                Aizvērt
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Table.Body>
            </Table>
        </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        const backButton = <NavLink to={`/ipasums/${propertyId}/biotopu_veidlapa`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        Visas biotopu veidlapa
                                        <Header.Subheader>{propertyData && propertyData.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        Visas biotopu veidlapas
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}/biotopu_veidlapa`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> Pievienot biotopu veidlapu
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}

export const AllHabitatsForm = createForm()(HabitatsFormWrapped as any);
