import * as React from "react";
import cookie from "react-cookies";

import { slide as SidebarMenu } from "react-burger-menu";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import { Helpers } from "../../utils/helpers";
import { LayoutProps } from "../LayoutWrapper";
import { Logo } from "../Logo";

interface HeaderMenuProps extends LayoutProps {
    mobileLogoReplace?: any;
}

interface HeaderMenuState {
    mobileMenuVisible: boolean;
}

export class HeaderMenu extends React.Component<HeaderMenuProps, HeaderMenuState> {
    constructor(props) {
        super(props);

        this.state = {
            mobileMenuVisible: false,
        };
    }

    logout = () => {
        cookie.remove("ozols-forest-token");
        this.props.resetAppState();
    }

    redirectState = () => {
        this.props.history.push("/pievedeju_statuss");
    }

    toHomepage = () => {
        this.props.history.push("/");
    }

    handleSidebarHide = () => this.setState({ mobileMenuVisible: false });

    isMenuOpen = (state) => this.setState({ mobileMenuVisible: state.isOpen });

    render() {
        const { activeUser } = this.props;

        const allAccessButtonMobile = (menuItem = false) => {
            const activeToText = this.props.getActiveType() === 100000000 ? "Meža meistars" : "Meža eksperts";
            const activeToId = this.props.getActiveType() === 100000000 ? 100000001 : 100000000;
            const buttonClick = (e) => {
                e.preventDefault();
                this.props.setActiveType(activeToId);
                this.handleSidebarHide();
            };

            if (menuItem) {
                return <a onClick={buttonClick} href="#">
                    {activeToText}
                </a>;
            }

            return <a
                onClick={buttonClick}
                href="#"
            >
                {activeToText}
            </a>;
        };

        const allAccessButton = (menuItem = false) => {
            const activeToText = this.props.getActiveType() === 100000000 ? "Meža meistars" : "Meža eksperts";
            const activeToId = this.props.getActiveType() === 100000000 ? 100000001 : 100000000;
            const buttonClick = (e) => {
                e.preventDefault();
                this.props.setActiveType(activeToId);
            };

            if (menuItem) {
                return <Menu.Item onClick={buttonClick}>
                    {activeToText}
                </Menu.Item>;
            }

            return <Menu.Item
                name={activeToText}
                onClick={buttonClick}
            />;
        };

        return (
            <div>
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                    <Menu secondary>
                        <Menu.Item>
                            {this.props.mobileLogoReplace ? this.props.mobileLogoReplace : <Logo className="menu--logo" onClick={this.toHomepage}/>}
                        </Menu.Item>

                        <Menu.Menu position="right" style={{ position: "relative" }}>
                            <SidebarMenu
                                isOpen={this.state.mobileMenuVisible}
                                onStateChange={this.isMenuOpen}
                                right
                            >
                                {activeUser && activeUser.accessType === 100000002 && allAccessButtonMobile(true)}
                                <a onClick={ this.logout } href="#">Iziet</a>
                            </SidebarMenu>
                        </Menu.Menu>
                    </Menu>
                </Responsive>

                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <Menu secondary>
                        <Menu.Item>
                            <Logo style={{ width: 100, paddingBottom: 4 }}/>
                        </Menu.Item>

                        {activeUser && activeUser.accessType === 100000002 && allAccessButton()}

                        <Menu.Menu position="right">
                            <Dropdown item text={Helpers.fullName(this.props.activeUser)}>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={this.logout}>Iziet</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Menu>
                    </Menu>
                </Responsive>
            </div>
        );
    }
}
