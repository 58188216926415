import axios from "axios";
import { createForm } from "rc-form";
import * as React from "react";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { DateInput } from "semantic-ui-calendar-react";
import { Button, Divider, Form, Loader } from "semantic-ui-react";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import { HeaderMenu } from "../../components/HeaderMenu";
import { ImageUploader } from "../../components/ImageUploader";
import { InlineEdit } from "../../components/InlineEdit";
import { ResponsiveElement } from "../../components/ResponsiveElement";
import { SelectField } from "../../components/SelectD";
import { BOOLEAN_VALUES } from "../../constants/constants";
import { IMAGE_TYPES } from "../../utils/constants";
import { Helpers } from "../../utils/helpers";
import { new_piegajiensValues } from "../DevelopmentStatus/helpers/helpers";
import {
    DOKUMENTI_NOSUTITI,
    KRAUTUVES_STATUSS,
    LIGUMA_PIELIKUMS,
    PIEVESANA,
    SAIMNIEKAM_ATSTATS_MATERIALS,
    SASKANOJUMI_OPTIONS,
    ZAGESANA,
} from "./constants";
import { ApproachFormState } from "./types/ApproachForm.type";

interface DevelopmentStatusMainProps {
    match: any;
    form: any;
}

class ApproachFormMain extends React.Component<DevelopmentStatusMainProps, ApproachFormState> {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loaded: false,
            approach: {} as any,
            property: {} as any,
            savingData: false,
            completeSection: false,
        };
    }

    componentDidMount() {
        this.fetchPropertyData();
    }

    fetchPropertyData = () => {
        const propertyId = this.props.match.params.propertyId;
        const approach = this.props.match.params.approach;

        axios.get(`${process.env.baseUrl}/api/properties/${propertyId}/development_status/approach/${approach}`, {
            headers: {
                Authorization: Helpers.getSessionId(),
            },
        })
        .then(response => {
            this.setState({
                loaded: true,
                approach: response.data.approach || {},
                property: response.data.property || {},
            });
        })
        .catch(_error => {
            this.setState({ loaded: true });
            Helpers.showError("Neizdevās ielādēt īpašuma datus!");
        });
    }

    handleDateChange = (_event, { _name, value }) => value;

    CAstate = (property) => {
        switch (property.new_cirsanasapliecinajums) {
            case 100000000:
                return "Nav";
            case 100000001:
                return "Iesniegts";
            case 100000002:
                return "Iegūts";
            default:
                return null;
        }
    }

    saveChanges = () => {
        const propertyId = this.props.match.params.propertyId;
        const approach = this.props.match.params.approach;

        this.props.form.validateFields((error, values) => {
            if (error) {
                Helpers.showError("Neizdevās saglabāt datus");
            }

            this.setState({ savingData: true }, () => {
                axios.put(`${process.env.baseUrl}/api/properties/${propertyId}/development_status/approach/${approach}`, {
                    ...values,
                }, {
                    headers: {
                        Authorization: Helpers.getSessionId(),
                    },
                })
                .then(response => {
                    if (response.data.success) {
                        this.setState({ savingData: false, redirect: true });
                    } else {
                        this.setState({ savingData: false });
                        Helpers.showError("Neizdevās saglabāt datus");
                    }
                })
                .catch(_error => {
                    this.setState({ savingData: false});
                    Helpers.showError("Neizdevās saglabāt datus");
                });
            });
        });
    }

    trimmingZero = (date) => {
        if (date < 10) {
            return "0" + date;
        }

        return date;
    }

    formatDate = (date) => {
        if (!date) {
            return "";
        }

        const local = new Date(date);
        return `${this.trimmingZero(local.getDate())}.${this.trimmingZero(local.getMonth() + 1)}.${local.getFullYear()}`;
    }

    // tslint:disable-next-line:cyclomatic-complexity
    render() {
        const { property, approach } = this.state;
        const propertyId = this.props.match.params.propertyId;
        const { getFieldProps, getFieldValue } = this.props.form;

        if (this.state.redirect) {
            return <Redirect to={`/ipasums/${propertyId}/izstrades_statusi`}/>;
        }

        const loadedPageContent =
            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge" }}>
                <Form>
                    <Grid>
                        <Header size="small" className="no--padding color--grey">Pirms izstrādes</Header>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Ciršanas apliecinājums</label>
                                {this.CAstate(property)}
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Saskaņojumi</label>
                                <InlineEdit
                                    input={
                                        <SelectField
                                            options={SASKANOJUMI_OPTIONS}
                                            {...getFieldProps("saskanojumi", {
                                                initialValue: approach.saskanojumi || SASKANOJUMI_OPTIONS[0].id,
                                            })}
                                        />
                                    }
                                    initVisible={+approach.saskanojumi !== SASKANOJUMI_OPTIONS[1].id}
                                    label={SASKANOJUMI_OPTIONS[1].label}
                                />
                            </Form.Field>
                        </Grid.Row>

                        {+getFieldValue("saskanojumi") === SASKANOJUMI_OPTIONS[2].id &&
                            <Grid.Row>
                                <Form.Field className="full--width">
                                    <label>Komentārs</label>
                                    <textarea
                                        {...getFieldProps("pirms_izst_komentars", {
                                            initialValue: approach.pirms_izst_komentars || "",
                                        })}
                                    />
                                </Form.Field>
                            </Grid.Row>
                        }

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Dokumenti izstrādātājiem nosūtīti</label>

                                <InlineEdit
                                    input={
                                        <SelectField
                                            options={DOKUMENTI_NOSUTITI}
                                            {...getFieldProps("dok_nosutiti", {
                                                initialValue: approach.dok_nosutiti || DOKUMENTI_NOSUTITI[0].id,
                                            })}
                                        />
                                    }
                                    initVisible={+approach.dok_nosutiti !== DOKUMENTI_NOSUTITI[1].id}
                                    label={DOKUMENTI_NOSUTITI[1].label}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Nogabalu plāns</label>
                                <ImageUploader
                                    type={IMAGE_TYPES.NOGABALI}
                                    propertyId={this.props.match.params.propertyId}
                                    cadastreNumber={+property.cadastre_number}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Līguma pielikums</label>

                                <InlineEdit
                                    input={
                                        <SelectField
                                            options={LIGUMA_PIELIKUMS}
                                            {...getFieldProps("liguma_pielik", {
                                                initialValue: approach.liguma_pielik || LIGUMA_PIELIKUMS[0].id,
                                            })}
                                        />
                                    }
                                    initVisible={+approach.liguma_pielik !== LIGUMA_PIELIKUMS[3].id}
                                    label={LIGUMA_PIELIKUMS[1].label}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Pievešanas ceļš iezīmēts dabā</label>

                                <InlineEdit
                                    input={
                                        <SelectField
                                            options={BOOLEAN_VALUES}
                                            {...getFieldProps("pieves_cels_daba", {
                                                initialValue: approach.pieves_cels_daba || BOOLEAN_VALUES[0].id,
                                            })}
                                        />
                                    }
                                    initVisible={approach.pieves_cels_daba !== BOOLEAN_VALUES[1].id}
                                    label={BOOLEAN_VALUES[1].label}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Krautuves vieta iezīmēta dabā</label>

                                <InlineEdit
                                    input={
                                        <SelectField
                                            options={BOOLEAN_VALUES}
                                            {...getFieldProps("kraut_vieta_iezim", {
                                                initialValue: approach.kraut_vieta_iezim || BOOLEAN_VALUES[0].id,
                                            })}
                                        />
                                    }
                                    initVisible={approach.kraut_vieta_iezim !== BOOLEAN_VALUES[1].id}
                                    label={BOOLEAN_VALUES[1].label}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Header size="small" className="no--padding color--grey">Izstrāde</Header>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Zāģēšana</label>

                                <InlineEdit
                                    input={
                                        <SelectField
                                            options={ZAGESANA}
                                            {...getFieldProps("zagesana", {
                                                initialValue: approach.zagesana || ZAGESANA[0].id,
                                            })}
                                        />
                                    }
                                    initVisible={approach.zagesana !== ZAGESANA[5].id}
                                    label={ZAGESANA[5].label}
                                />
                            </Form.Field>
                        </Grid.Row>

                        {+getFieldValue("zagesana") === ZAGESANA[1].id &&
                            <Grid.Row>
                                <Form.Field className="full--width">
                                    <label>Komentārs</label>
                                    <textarea
                                        {...getFieldProps("zagesana_komentars", {
                                            initialValue: approach.zagesana_komentars || "",
                                        })}
                                    />
                                </Form.Field>
                            </Grid.Row>
                        }

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Pievešana</label>

                                <InlineEdit
                                    input={
                                        <SelectField
                                            options={PIEVESANA}
                                            {...getFieldProps("pievesana", {
                                                initialValue: approach.pievesana || PIEVESANA[0].id,
                                            })}
                                        />
                                    }
                                    initVisible={approach.pievesana !== PIEVESANA[5].id}
                                    label={PIEVESANA[5].label}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Pievešanas termiņš</label>

                                <DateInput
                                    name="date"
                                    iconPosition="left"
                                    dateFormat="DD.MM.YYYY"
                                    {...getFieldProps("pievesanas_termins", {
                                        initialValue: this.formatDate(approach.pievesanas_termins),
                                        getValueFromEvent: this.handleDateChange,
                                    })}
                                />
                            </Form.Field>
                        </Grid.Row>

                        {+getFieldValue("pievesana") === ZAGESANA[1].id &&
                            <Grid.Row>
                                <Form.Field className="full--width">
                                    <label>Komentārs</label>
                                    <textarea
                                        {...getFieldProps("pievesana_komentars", {
                                            initialValue: approach.pievesana_komentars || "",
                                        })}
                                    />
                                </Form.Field>
                            </Grid.Row>
                        }

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Saimniekam atstāts materiāls?</label>

                                <InlineEdit
                                    input={
                                        <SelectField
                                            options={SAIMNIEKAM_ATSTATS_MATERIALS}
                                            {...getFieldProps("atstats_materials", {
                                                initialValue: approach.atstats_materials || SAIMNIEKAM_ATSTATS_MATERIALS[0].id,
                                            })}
                                        />
                                    }
                                    initVisible={approach.atstats_materials !== SAIMNIEKAM_ATSTATS_MATERIALS[1].id}
                                    label={SAIMNIEKAM_ATSTATS_MATERIALS[1].label}
                                />
                            </Form.Field>
                        </Grid.Row>

                        <Grid.Row>
                            <Form.Field className="full--width">
                                <label>Krautuves statuss</label>
                                {approach.krautuves_status && KRAUTUVES_STATUSS[approach.krautuves_status] || "Trūkst datu"}
                            </Form.Field>
                        </Grid.Row>

                        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="full--width">
                            <Divider />
                        </Responsive>

                        <Grid.Row >
                            <ResponsiveElement desktopProps={{ size: "large" }} mobileProps={{ size: "huge", style: { width: "100%" } }}>
                                <Button onClick={this.saveChanges} color="green" loading={this.state.savingData}>
                                    <Icon name="save"/>
                                    Saglabāt
                                </Button>
                            </ResponsiveElement>
                        </Grid.Row>
                    </Grid>
                </Form>
            </ResponsiveElement>;

        const pageContent = this.state.loaded ? loadedPageContent : <Loader active size="large" inline="centered">Lūdzu uzgaidiet</Loader>;

        const backButton = <NavLink to={`/ipasums/${this.props.match.params.propertyId}/izstrades_statusi`} className="ui icon left labeled button huge" style={{ color: "#fff" }}>
            <Icon name="angle left"/> Atpakaļ
        </NavLink>;

        return (
            <div>
                <header style={{ background: "#fff" }}>
                    <div className="layout-wrapper">
                        <HeaderMenu { ...this.props } mobileLogoReplace={backButton}/>

                        <Responsive as={Grid}>
                            <Grid.Row style={{ paddingTop: 20, paddingBottom: 0 }}>
                                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} mobile={16} computer={16} textAlign="center" as={Grid.Column}>
                                    <Header as="h1" style={{ margin: "40px 0 15px 0" }}>
                                        {new_piegajiensValues[approach.new_piegajiens]} piegājiens
                                        <Header.Subheader>{property && property.felling_area_name}</Header.Subheader>
                                    </Header>
                                </Responsive>

                                <Responsive minWidth={Responsive.onlyTablet.minWidth} mobile={8} computer={8} textAlign="left" as={Grid.Column}>
                                    <Header as="h1">
                                        {new_piegajiensValues[approach.new_piegajiens]} piegājiens
                                        <Header.Subheader>
                                            <NavLink to={`/ipasums/${this.props.match.params.propertyId}/izstrades_statusi`} style={{ color: "#00000099" }}>
                                                <Icon name="angle left" style={{ margin: 0 }}/> {property && property.felling_area_name}
                                            </NavLink>
                                        </Header.Subheader>
                                    </Header>
                                </Responsive>
                            </Grid.Row>
                        </Responsive>
                    </div>
                </header>

                <div className="layout-wrapper">
                    {pageContent}
                </div>
            </div>
        );
    }
}

export const ApproachForm = createForm()(withRouter(ApproachFormMain as any));
